<div>
    <br>
    <h1>{{'blogText171'|translate}}</h1>
    <h1>{{'blogText172'|translate}}</h1>
    <h1>{{'blogText173'|translate}}</h1>
    <br>
    <div class="col-12 text-center">
        <button   id="downloadEntireBiblePaln" class="main-btn" (click)="downloadEntireCalendar()">{{'Bibelleseplan hinzufügen' | translate}}</button>
    </div>
    <br>
    <div id='calendar2'></div>
</div>
