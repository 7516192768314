import { Component, OnInit } from '@angular/core';
// import blog from '../../../data/blog/blog.json';
import blogcategory from '../../../data/blog/blogcategory.json';
import blogtags from '../../../data/tags.json';
import {BlogFromBackendService} from '../../../service/blog-from-backend.service';
import {Blog} from '../../../data/model/blog.model';
@Component({
  selector: 'app-blogsidebar',
  templateUrl: './blogsidebar.component.html',
  styleUrls: ['./blogsidebar.component.css']
})
export class BlogsidebarComponent implements OnInit {

  constructor( private blogFromBackendService: BlogFromBackendService) { }
  public blog: Blog[];
  public blogcategory = [
    {
      id: 0,
      title: 'blogText100',
      isChecked: false
    },
    {
      id: 1,
      title: 'blogText101',
      isChecked: false
    },
    {
      id: 2,
      title: 'blogText102',
      isChecked: false
    },
    {
      id: 3,
      title: 'blogText103',
      isChecked: false
    },
    {
      id: 4,
      title: 'blogText104',
      isChecked: false
    }
  ];
   public blogtags = [
     {
       id: 1,
       title: 'blogText105',
       isChecked: false

     },
     {
       id: 2,
       title: 'blogText106',
       isChecked: false
     },
     {
       id: 3,
       title: 'blogText107',
       isChecked: false
     },
     {
       id: 4,
       title: 'blogText108',
       isChecked: false
     },
     {
       id: 5,
       title: 'blogText109',
       isChecked: false
     },
     {
       id: 6,
       title: 'blogText110',
       isChecked: false
     },
     {
       id: 7,
       title: 'blogText111',
       isChecked: false
     },
     {
       id: 8,
       title: 'blogText112',
       isChecked: false
     }
   ];
  public getBlogFromBackend(): any{
    return this.blogFromBackendService.getBlogsByType('nachricht').subscribe((data: Blog[]) => {
        this.blog = data;
    });
      }
  ngOnInit(): void {
    this.getBlogFromBackend();
  }


}
