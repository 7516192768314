import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Blog} from '../data/model/blog.model';
import blog from './../data/blog/blog.json';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BlogFromBackendService {
    lang = localStorage.getItem('lang') || 'de';
    private apiUrl: string = environment.apiUrl;
    private urlBlogs = this.apiUrl + '/blog/';
    private urlBlogsLast = this.apiUrl + '/blog/last';
    private urlBlogsID = this.apiUrl + '/blog/id/';
    private urlBlogsAllID = this.apiUrl + '/blog/all/id/';
    private urlId = this.apiUrl + '/idsAccumulation/blog';
    blogs: Blog[];
    public blogpost = blog;
    public blogId: number;

    constructor(private httpClient: HttpClient) {
    }

    // tslint:disable-next-line:typedef
    public getBlogs() {
        return this.httpClient.get<Blog[]>(this.urlBlogs + '/' +  this.lang );
    }

    // tslint:disable-next-line:typedef
    public getBlogsByType(type: string) {
        return this.httpClient.get<Blog[]>(this.urlBlogs + type + '/' + this.lang );
    }
    // tslint:disable-next-line:typedef
    public getBlogsForSearch(txt: string) {
        return this.httpClient.get<Blog[]>(this.urlBlogs + 'txt/' + txt + '/' + this.lang + '/');
    }

    // tslint:disable-next-line:typedef
    public getBlogsForSearchById(id: number) {
        return this.httpClient.get<Blog[]>(this.urlBlogsID + id);
    }
    // tslint:disable-next-line:typedef
    public getBlogsForSearchByAllContainId(id: number) {
        return this.httpClient.get<Blog[]>(this.urlBlogsAllID + id);
    }
    // tslint:disable-next-line:typedef
    // public getIdLastBlog() {
    //     return this.httpClient.get<number>(this.urlBlogsLast).subscribe((data: number) => {
    //         return this.blogId = data;
    //     });
    // }


    // public postNewBlog(blog: Blog) {
    //   return this.httpClient.post(this.UrlBlogs, blog);
    // }
    // @ts-ignore
    // public getBlogsArrFrom(): Blog[]{
    //    this.httpClient.get<Blog[]>(this.UrlBlogs).subscribe((data: Blog[]) => {
    //      this.blogs = data;
    //     return data;
    //    });
    // }
    // public getBlogsArr(): Blog[]{
    //   this.getBlogsArrFrom();
    //   // console.log(this.blogs);
    //   return this.blogs;
    // }

    // tslint:disable-next-line:typedef
    postNewBlog(newBlog: Blog) {
        return this.httpClient.post(this.urlBlogs, newBlog);
    }

    // tslint:disable-next-line:typedef
    updateNewBlog(id: number, updatedBlog: Blog) {
        return this.httpClient.put(this.urlBlogs + id, updatedBlog);
    }

    deleteBlog(id: number): void {
        this.httpClient.delete(this.urlBlogs + id).subscribe(data => {
            // console.log(data);
        });
    }

    // tslint:disable-next-line:typedef
    updateBlogID() {
           return this.httpClient.put(this.urlId, ' {}').subscribe(data => {
        });
    }

    // tslint:disable-next-line:typedef
    getBlogID() {
        return this.httpClient.get(this.urlId);
    }
    // tslint:disable-next-line:typedef
    public getBlogsForSearchAndType(type: string, txt: string) {
        return this.httpClient.get<Blog[]>(this.urlBlogs + type + '/' + txt + this.lang + '/');
    }
}
