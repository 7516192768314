<breadcrumb #parent>
    <section class="breadcrumb-section bg-img-c" [ngStyle]="{'background-image':' url(' + path + ')'}">
<!--        <h1>{{path}}</h1>-->
        <div class="container">
            <div class="breadcrumb-text ">
                <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                    <h1 class="page-title ">{{ route.displayName | translate}}</h1>
                    <ul>
                        <li ><a routerLink="/">{{'Home'| translate}}</a></li>
                        <li *ngIf="!route.terminal" >
                            <a href="" [routerLink]="[route.url]">{{ route.displayName | translate}}</a>
                        </li >
                        <li *ngIf="route.terminal" >{{ route.displayName | translate}}</li>
                    </ul>
                </ng-template>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
</breadcrumb>

<!--style="background-image: url(assets/img/breadcrumb.webp);"-->
