import { Injectable } from '@angular/core';
import { startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

export interface CalendarEvent {
    title: string;
    start: Date;
    end: Date;
}

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    private calendarEvents: CalendarEvent[] = []; // Array to store calendar events

    private eventsSubject = new BehaviorSubject<CalendarEvent[]>([]);
    public events$ = this.eventsSubject.asObservable();

    constructor() {}

    setEvents(events: CalendarEvent[]): void {
        this.calendarEvents = events;
        this.updateWeeklyEvents(); // Update weekly events whenever new events are set
    }

    private updateWeeklyEvents(): void {
        const weeklyEvents = this.getWeeklyEvents();
        this.eventsSubject.next(weeklyEvents); // Emit only the weekly events
    }

    private getWeeklyEvents(): CalendarEvent[] {
        const now = new Date();
        const start = startOfWeek(now, { weekStartsOn: 1 }); // Week starts on Monday
        const end = endOfWeek(now, { weekStartsOn: 1 }); // Week ends on Sunday

        return this.calendarEvents.filter(event =>
            isWithinInterval(new Date(event.start), { start, end }) ||
            isWithinInterval(new Date(event.end), { start, end })
        );
    }
}
