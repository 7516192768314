<!-- About Widget -->
<div class="widget about-widget">
    <div class="logo mb-30">
        <img src="assets/img/logo-3.webp" alt="img">
    </div>
    <p>{{'blogText165'|translate}}</p>
</div>
<!-- Contact information Widget -->
<div class="widget contact-info-widget">
    <h5 class="widget-title">{{'Kontaktieren Sie uns'| translate}}</h5>
    <ul>
<!--        <li>-->
<!--            <i class="far fa-map-marked-alt"></i>-->
<!--            Fünfhausgasse 25, 1150 Wien-->
<!--        </li>-->
        <li>
            <a href="https://www.google.com/maps/place/Antiochenisch-orthodoxe+Kirche+in+Wien/@48.1911953,16.3318594,17z/data=!3m2!4b1!5s0x476da81eb9034d63:0x6716bb72e376368d!4m5!3m4!1s0x476da95b2064538f:0xd189dca646577ab8!8m2!3d48.1911953!4d16.3340534" target="_blank">
                <i class="far fa-map-marker-alt"></i>Fünfhausgasse 25, 1150 Wien</a>
        </li>
        <!--        <li>-->
<!--            <i class=" far fa-map-marker-alt far fa-phone"></i>-->
<!--            0800-123456 (24/7 Support Line)-->
<!--        </li>-->
        <li>
            <a [routerLink]="['/contact'] "><i class="far fa-envelope-open"></i>antioch@antiochorthodox.at</a>
        </li>
    </ul>
</div>
<!-- tweets Widget -->
<!--<div class="widget tweets-widget">-->
<!--    <h5 class="widget-title">Recent tweets</h5>-->
<!--    <ul>-->
<!--        <li>-->
<!--            <a href="#">-->
<!--                <i class="fab fa-twitter"></i>-->
<!--                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit-->
<!--                voluptatem accusantium...-->
<!--            </a>-->
<!--        </li>-->
<!--        <li>-->
<!--            <a href="#">-->
<!--                <i class="fab fa-twitter"></i>-->
<!--                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit-->
<!--                voluptatem accusantium...-->
<!--            </a>-->
<!--        </li>-->
<!--        <li>-->
<!--            <a href="#">-->
<!--                <i class="fab fa-twitter"></i>-->
<!--                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit-->
<!--                voluptatem accusantium...-->
<!--            </a>-->
<!--        </li>-->
<!--    </ul>-->
<!--</div>-->
<!-- Social Link -->
<div class="widget social-link">
    <h5 class="widget-title">{{'Folgen Sie uns'|translate}}</h5>
    <ul>
        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
        <li><a href="#"><i class="fab fa-behance"></i></a></li>
        <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
        <li><a href="#"><i class="fab fa-google"></i></a></li>
    </ul>
</div>
