<header class="header-three sticky-header" id="can-sticky">
    <!-- Header Menu  -->
    <div class="header-nav">
        <div class="container-fluid">
            <div class="nav-container" [ngClass]="visible ? '' : 'breakpoint-on'" (window:resize)="detectHeader()">
                <!-- Site Logo -->
                <div class="site-logo">
                    <a routerLink="/"><img src="assets/img/logo-3.webp" alt="logo"></a>
                </div>

                <!-- Main Menu -->
                <div class="nav-menu d-lg-flex align-items-center" [ngClass]="navmethod ? '' : 'menu-on'">

                    <!-- Navbar Close Icon -->
                    <div class="navbar-close">
                        <div class="cross-wrap" (click)="toggleNav()"><span></span><span></span></div>
                    </div>

                    <!-- Mneu Items -->
                    <div class="menu-items">
                        <ul>
                            <li class="menu-item" [ngClass]="item.child == true ? 'has-submemu' : ''"
                                *ngFor="let item of navigation">
                                <a href="javascript:void(0)" (click)="trigger(item)"
                                    *ngIf="item.child">{{item.linkText}}</a>
                                <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                                <ul class="submenu" *ngIf="item.child" [ngClass]="item.open ? 'd-block' : ''">
                                    <li class="menu-item" [ngClass]="item.child == true ? 'has-submemu' : ''"
                                        *ngFor="let item of item.submenu">
                                        <a href="javascript:void(0)" (click)="trigger(item)"
                                            *ngIf="item.child">{{item.linkText}}</a>
                                        <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                                        <ul class="submenu" *ngIf="item.child" [ngClass]="item.open ? 'd-block' : ''">
                                            <li class="menu-item" *ngFor="let item of item.submenu">
                                                <a routerLink="{{item.link}}">{{item.linkText}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- Pushed Item -->
                    <div class="nav-pushed-item"></div>
                </div>

                <!-- Navbar Extra  -->
                <div class="navbar-extra d-flex align-items-center">
                    <!-- Social Link -->
                    <div class="menu-social nav-push-item">
                        <div class="menu-social-link">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="#"><i class="fab fa-behance"></i></a>
                            <a href="#"><i class="fab fa-dribbble"></i></a>
                        </div>
                    </div>
                    <!-- off canvas menu toggler-->
                    <div class="offcanvas-toggler" (click)="toggleCanvas()">
                        <span><span></span><span></span><span></span></span>
                    </div>
                    <!-- Navbar Toggler -->
                    <div class="navbar-toggler" [ngClass]="navmethod ? '' : 'active'" (click)="toggleNav()">
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Canvas -->
<div class="offcanvas-wrapper" [ngClass]="canvasmethod ? '' : 'show-offcanvas'">
    <div class="offcanvas-overly" [ngClass]="canvasmethod ? '' : 'show-overly'" (click)="toggleCanvas()"></div>
    <div class="offcanvas-widget">
        <a href="javascript:void(0)" class="offcanvas-close" (click)="toggleCanvas()"><i class="fal fa-times"></i></a>
        <app-canvas></app-canvas>
    </div>
</div>
