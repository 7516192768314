import { Component, OnInit } from '@angular/core';
// import blogpost from '../../../data/blog/blog.json';
import {Blog} from '../../../data/model/blog.model';
import {BlogFromBackendService} from '../../../service/blog-from-backend.service';

@Component({
  selector: 'app-relatedpost',
  templateUrl: './relatedpost.component.html',
  styleUrls: ['./relatedpost.component.css']
})
export class RelatedpostComponent implements OnInit {

  constructor(private blogFromBackendService: BlogFromBackendService) { }
  public blogpost: Blog[];

  public getBlogFromBackend(): any{
    return this.blogFromBackendService.getBlogs().subscribe((data: Blog[]) => {
      this.blogpost = data;
    });
  }
  ngOnInit(): void {
    this.getBlogFromBackend();
  }

}
