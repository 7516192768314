<footer [class]="layout">
    <div class="container">
        <div class="footer-widget">
            <div class="row">

                <!--                <div class="col-lg-8 col-sm-7 order-2"></div>-->
                <div class="col-lg-3 col-sm-6 order-1">
                    <div class="widget nav-widget">
                        <h4 class="widget-title " style="color:#EBD47B;">{{'Links' | translate}}</h4>
                        <ul>
                            <li><i
                                    class="img-bg-rrr"></i><a href="https://rum-orthodox.de/" style="color:#EBD47B;"   target="_blank"><i
                                    class="fas fa-church img-bg-rrr"></i>{{' Metropolie von Deutschland und Mitteleuropa' | translate}}</a>
                            </li>
                            <li><i
                                    class="img-bg-rrr"></i><a href="https://www.antiochpatriarchate.org/"
                                                              style="color:#EBD47B;"   target="_blank"><i
                                    class="fas fa-church img-bg-rrr"></i>{{'Patriarchat von Antiochia' | translate}}</a></li>

                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 order-lg-4 order-2">
                    <div class="row">
                        <div class="widget site-info-widget">
                            <h4 class="widget-title" style="color:#EBD47B;">{{'Kontaktiere uns' | translate}}</h4>
                            <!--                                <p></p>-->
                            <ul class="social-links" style="color:#EBD47B;">
                                <li><a href="https://www.facebook.com/AntiochenischorthodoxeKircheinWien/"   target="_blank"><i
                                        class="fab fa-facebook-f img-bg-rrr"></i>&nbsp;&nbsp;Facebook</a></li>
                                <li><a href="https://www.instagram.com/antiochorthodox_wien/"   target="_blank"><i
                                        class="fab fa-instagram img-bg-rrr"></i>&nbsp;&nbsp;Instagram</a></li>
                                <li><a href="https://www.youtube.com/channel/UC8QkmyiXwVYkeiG-3f8Mq5Q/featured"   target="_blank"><i
                                        class="fab fa-youtube-square img-bg-rrr"></i>&nbsp;&nbsp;Youtube</a></li>
                                <li><a [routerLink]="['/contact'] "><i
                                        class="far fa-envelope-open img-bg-rrr"></i>&nbsp;antioch@antiochorthodox.at</a>
                                </li>
                                <li>
                                    <a href="https://www.google.com/maps/place/Antiochenisch-orthodoxe+Kirche+in+Wien/@48.1911953,16.3318594,17z/data=!3m2!4b1!5s0x476da81eb9034d63:0x6716bb72e376368d!4m5!3m4!1s0x476da95b2064538f:0xd189dca646577ab8!8m2!3d48.1911953!4d16.3340534"
                                       target="_blank">
                                        <i class="far fa-map-marker-alt"></i>&nbsp;&nbsp;Fünfhausgasse 25, 1150 Wien</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-5 order-3">
                    <div class="widget nav-widget">
                        <h4 class="widget-title " style="color:#EBD47B;">{{'Zur Unterstützung' | translate}}</h4>
                        <ul>
                            <li><h6 style="color:#EBD47B;">{{'blogText6' | translate}}</h6></li>
                            <li class="img-bg-rrr"><i class="fal fa-hand-holding-heart"></i>&nbsp;&nbsp;IBAN: AT46 1200
                                0100 0280 7252
                            </li>
                            <!--                            <li><a href="https://www.youtube.com/channel/UC8QkmyiXwVYkeiG-3f8Mq5Q/featured"><i-->
                            <!--                                    class="fab fa-youtube-square img-bg-rrr"></i></a>   Youtube</li>-->
                        </ul>
                    </div>
                </div>
                <!--                INSTGRAM-->
                <div class="col-lg-4 col-sm-5 order-lg-5 order-4">
                    <div class="widget insta-feed-widget">
                        <h4 class="widget-title" style="color:#EBD47B;">{{'Folge uns auf Instagram' | translate}}</h4>
                        <div class="insta-images">
                            <div class="insta-img" [style.background-image]="'url('+item.img+')'"
                                 *ngFor="let item of instagram">
                                <a href="https://www.instagram.com/antiochorthodox_wien/">
                                    <i class="fab fa-instagram img-bg-rrr"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--            </div>-->
            </div>
            <!--        <div class="footer-copyright" >-->
            <!--            <i class="fas fa-church img-bg-rrr"></i><a href="https://rum-orthodox.de/"  style="color:#EBD47B;" >&nbsp;&nbsp;&nbsp;&nbsp;Antiochenisch-Orthodoxe Metropolie von Deutschland und Mitteleuropa&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>-->

            <!--            <i class="fas fa-church img-bg-rrr"></i><a href="https://www.antiochpatriarchate.org/home/"  style="color:#EBD47B;">&nbsp;&nbsp;&nbsp;&nbsp;Patriarchat von Antiochia</a>-->
            <!--            </div>-->
            <div class="footer-copyright">
                <p class="copyright-text" style="color:#EBD47B;">
                    <span style="color:#EBD47B;">Copyright © 2022 &nbsp;<a href="antiochorthodox.at &nbsp;"
                                                                           style="color:#EBD47B;">antiochorthodox.at&nbsp;</a>Antiochenisch-Orthodoxe Metropolie.</span>
                    <a routerLink="/datenschutzerklärung" href="/datenschutzerklärung" style="color:#EBD47B;">Datenschutzerklärung</a>
                    <a routerLink="/impressum" href="/impressum" style="color:#EBD47B;">Impressum</a>
                    <span style="color:#EBD47B;">Alle Rechte vorbehalten.</span>
                </p>
                <a href="javascript:void(0)" class="back-to-top" (click)="gotoTop()" style="color:#EBD47B;"><i
                        class="far fa-angle-up img-bg-rrr"></i></a>
            </div>
        </div>
    </div>
    <!-- Lines -->
    <!--    <img [src]="shape" alt="line-shape" [class]="shapeclass">-->
    <!--    <img [src]="shape2" alt="line-shape" [class]="shape2class">-->
</footer>
