import { Component, OnInit, HostListener, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import navigation from '../../../data/navigation.json';
import { Stamp } from '../../../data/model/Stamp.model';
import { SiteSetting } from '../../../data/model/siteSetting.model';
import { SiteSittingService } from '../../../service/site-sitting.service';

@Component({
    selector: 'app-header-two',
    templateUrl: './header-two.component.html',
    styleUrls: ['./header-two.component.css']
})
export class HeaderTwoComponent implements OnInit {

    constructor(@Inject(DOCUMENT) private document: Document,
                private renderer: Renderer2,
                private siteSittingService: SiteSittingService) { }

    lang = localStorage.getItem('lang') || 'de';
    public navigation = navigation;
    navmethod = true;
    canvasmethod = true;
    open = false;
    visible = false;
    breakpoint = 991;
    public innerWidth: any;

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event): void  {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            document.getElementById('can-sticky').classList.add('sticky-on');
        } else {
            document.getElementById('can-sticky').classList.remove('sticky-on');
        }
    }

    toggleNav() : void {
        this.navmethod = !this.navmethod;
    }

    toggleCanvas() : void  {
        this.canvasmethod = !this.canvasmethod;
    }

    trigger(item: { open: boolean; }) : void  {
        item.open = !item.open;
    }

    detectHeader() : void  {
        this.innerWidth = window.innerWidth;
        this.visible = this.innerWidth >= this.breakpoint;
    }

    ngOnInit(): void {
        this.lang = localStorage.getItem('lang') || 'de';
        this.detectHeader();
    }

    changeLang(lang): void  {
        localStorage.setItem('lang', lang);
        this.lang = lang;

        // Check if toblogInput exists and has a value
        const toblogInput = document.getElementsByName('toblog')[0] as HTMLInputElement;
        if (toblogInput) {
            const newLocation = toblogInput.value;
            // Create an anchor element dynamically
            const anchor = this.renderer.createElement('a');
            this.renderer.setProperty(anchor, 'href', newLocation);
            anchor.click(); // Simulate a click on the anchor to redirect
                 } else {
            window.location.reload();
        }
    }
}
