<header class="header-two sticky-header" id="can-sticky">
    <!-- Header Topbar -->
    <div class="header-topbar">
        <div class="container-fluid container-1600">
            <div class="header-topbar-inner d-md-flex align-items-center justify-content-between">
                <ul>
                    <div class="fadein">
                        <img width=160px height=156px id="f2" src="assets/img/banner/logo02.resized.png" alt="l2">
                        <img width=160px height=160px id="f1" src="assets/img/banner/logo01.resized.png" alt="l1">
                    </div>
                </ul>
                <!-- Contact Info -->
                <ul class="contact-info ">
                    <!--                    <li>-->
                    <!--                        <a href="#"><i class="far fa-phone"></i>+012 (345) 6789</a>-->
                    <!--                    </li>-->
                    <li>
                        <a [routerLink]="['/contact'] "><i
                                class="far fa-envelope-open"></i>antioch@antiochorthodox.at</a>
                    </li>
                    <li>
                        <a href="https://www.google.com/maps/place/Antiochenisch-orthodoxe+Kirche+in+Wien/@48.1911953,16.3318594,17z/data=!3m2!4b1!5s0x476da81eb9034d63:0x6716bb72e376368d!4m5!3m4!1s0x476da95b2064538f:0xd189dca646577ab8!8m2!3d48.1911953!4d16.3340534"
                           target="_blank">
                            <i class="far fa-map-marker-alt"></i>Fünfhausgasse 25, 1150 Wien</a>
                    </li>
                </ul>
                <!-- Social Links -->
                <ul class="social-links">
                    <li>
                        <a href="https://www.facebook.com/AntiochenischorthodoxeKircheinWien/" target="_blank"><i
                                class="fab fa-facebook-f"></i></a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UC8QkmyiXwVYkeiG-3f8Mq5Q" target="_blank"><i
                                class="fab fa-youtube"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/antiochorthodox_wien/" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                    </li>
                    <!--                    <li>-->
                    <!--                        <a href="#"><i class="fab fa-youtube-square"></i></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a href="#"><i class="fab fa-dribbble"></i></a>-->
                    <!--                    </li>-->
                </ul>
            </div>
        </div>
    </div>
    <!-- Header Menu  -->
    <div class="header-nav sticky-nav">
        <div class="container-fluid container-1600">
            <div class="nav-container" [ngClass]="visible ? '' : 'breakpoint-on'" (window:resize)="detectHeader()">
                <!-- Site Logo -->
                <div class="site-logo">
                    <!--                    <a routerLink="/"><img src="assets/img/logo-2.png" alt="logo"></a>-->
                    <!--                    <a routerLink="/"><img width=200px height=200px src="assets/img/banner/2logo2.gif" alt="logo"></a>-->
                    <!--                    <video width=200px height=200px autoplay muted loop oncanplay="this.muted=true">-->
                    <!--                        <source src="assets/img/banner/2logo.webm" type="video/webm">-->
                    <!--                    </video>-->

                </div>
                <!-- Main Menu -->
                <div class="nav-menu d-lg-flex align-items-center" [ngClass]="navmethod ? '' : 'menu-on'">

                    <!-- Navbar Close Icon -->
                    <div class="navbar-close">
                        <div class="cross-wrap" (click)="toggleNav()"><span></span><span></span></div>
                    </div>

                    <!-- Mneu Items -->
                    <div class="menu-items ">
                        <ul>
                            <li class="menu-item " [ngClass]="item.child == true ? 'has-submemu' : ''"
                                *ngFor="let item of navigation">
                                <a href="javascript:void(0)" (click)="trigger(item)"
                                   *ngIf="item.child">{{item.linkText | translate}}</a>
                                <a routerLink="{{item.link | translate}}" *ngIf="!item.child">{{item.linkText | translate}}</a>
                                <ul class="submenu" *ngIf="item.child" [ngClass]="item.open ? 'd-block' : ''">
                                    <li class="menu-item" [ngClass]="item.child == true ? 'has-submemu' : ''"
                                        *ngFor="let item of item.submenu">
                                        <a href="javascript:void(0)" (click)="trigger(item)"
                                           *ngIf="item.child">{{item.linkText | translate}}</a>
                                        <a routerLink="{{item.link | translate}}" *ngIf="!item.child">{{item.linkText | translate}}</a>
                                        <ul class="submenu" *ngIf="item.child" [ngClass]="item.open ? 'd-block' : ''">
                                            <li class="menu-item" *ngFor="let item of item.submenu">
                                                <a routerLink="{{item.link | translate}}">{{item.linkText | translate}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- Pushed Item -->
                    <div class="nav-pushed-item"></div>
                </div>

                <!-- Navbar Extra  -->
                <div class="navbar-extra d-flex align-items-center custom-ltr-container">
                    <div class="language-selection">
                        <select (change)="changeLang($event.target.value)">
<!--                            <a class="language-btn" href="#">DE<i class="far fa-angle-down"></i></a>-->
                            <option value="de" [selected]="lang === 'de' || lang === null" >De</option>
                            <option value="ar" [selected]="lang== 'ar'" >Ar</option>
                        </select>
                    </div>
                    <!-- language selection  imprtant rrrr to modfy later-->
                    <!--                    <div class="language-selection">-->
                    <!--                                            <a class="language-btn" href="#">DE<i class="far fa-angle-down"></i></a>-->
                    <!--                        <ul class="language-list">-->
                    <!--                            <li><a href="#">DE</a></li>-->
                    <!--                            <li><a href="#">AR</a></li>-->
                    <!--                            &lt;!&ndash;                            <li><a href="#">USA</a></li>&ndash;&gt;-->
                    <!--                        </ul>-->
                    <!--                    </div>  -->
                    <!-- off canvas menu toggler-->
                    <div class="offcanvas-toggler" (click)="toggleCanvas()">
                        <span><span></span><span></span><span></span></span>
                    </div>
                    <!-- Navbar Toggler -->
                    <div class="navbar-toggler" [ngClass]="navmethod ? '' : 'active'" (click)="toggleNav()">
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Canvas -->
<div class="offcanvas-wrapper" [ngClass]="canvasmethod ? '' : 'show-offcanvas'">
    <div class="offcanvas-overly" [ngClass]="canvasmethod ? '' : 'show-overly'" (click)="toggleCanvas()"></div>
    <div class="offcanvas-widget">
        <a href="javascript:void(0)" class="offcanvas-close" (click)="toggleCanvas()"><i class="fal fa-times"></i></a>
        <app-canvas></app-canvas>
    </div>
</div>
