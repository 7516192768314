import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-analytics-consent-modal',
  templateUrl: './analytics-consent-modal.component.html',
  styleUrls: ['./analytics-consent-modal.component.css']
})
export class AnalyticsConsentModalComponent {

  constructor(public dialogRef: MatDialogRef<AnalyticsConsentModalComponent>) {}

  onClose(): void {
    localStorage.setItem('consentGiven', 'true'); // Set a flag in local storage
    this.dialogRef.close();
  }
}
