// newsline.component.ts
import {Component, OnInit} from '@angular/core';
import {CalendarEvent, CalendarService} from '../../../service/calendar-service.service';


@Component({
    selector: 'app-newsline',
    templateUrl: './newsline.component.html',
    styleUrls: ['./newsline.component.css']
})
export class NewslineComponent implements OnInit {
    events: CalendarEvent[] = [];

    constructor(private calendarService: CalendarService) {
    }

    ngOnInit(): void {
        this.calendarService.events$.subscribe(events => {
            this.events = events;
               });
    }
}
