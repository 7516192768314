import { Component, OnInit } from '@angular/core';
import category from '../../../data/bibilothek/bibilothekcategory.json';
import tags from '../../../data/tags.json';

@Component({
  selector: 'app-shopsidebar',
  templateUrl: './shopsidebar.component.html',
  styleUrls: ['./shopsidebar.component.css']
})
export class ShopsidebarComponent implements OnInit {

  constructor() { }
  public category = [
    {
      id: 1,
      title: 'blogText113',
      isChecked: false
    },
    {
      id: 2,
      title: 'blogText114',
      isChecked: false
    },
    {
      id: 3,
      title: 'blogText115',
      isChecked: false
    },
    {
      id: 4,
      title: 'blogText116',
      isChecked: false
    },
    {
      id: 5,
      title: 'blogText117'
    },
    {
      id: 6,
      title: 'blogText118',
      isChecked: false
    },
    {
      id: 7,
      title: 'blogText119',
      isChecked: false
    },
    {
      id: 8,
      title: 'blogText120',
      isChecked: false
    }
  ];
  public tags = [
    {
      id: 1,
      title: 'blogText105',
      isChecked: false

    },
    {
      id: 2,
      title: 'blogText106',
      isChecked: false
    },
    {
      id: 3,
      title: 'blogText107',
      isChecked: false
    },
    {
      id: 4,
      title: 'blogText108',
      isChecked: false
    },
    {
      id: 5,
      title: 'blogText109',
      isChecked: false
    },
    {
      id: 6,
      title: 'blogText110',
      isChecked: false
    },
    {
      id: 7,
      title: 'blogText111',
      isChecked: false
    },
    {
      id: 8,
      title: 'blogText112',
      isChecked: false
    }
  ];

  ngOnInit(): void {
  }

}
