import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Calendar} from '@fullcalendar/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CalendarEvent, CalendarService} from '../../../service/calendar-service.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import allLocales from '@fullcalendar/core/locales-all';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-calendar2',
  templateUrl: './calendar2.component.html',
  styleUrls: ['./calendar2.component.css']
})
export class Calendar2Component implements OnInit, AfterViewInit, OnDestroy{
  // ننمو بالروح Wachsen im Geist
  calendar2: Calendar;
  langChangeSubscription: Subscription;
  lang = localStorage.getItem('lang');

  constructor(private elementRef: ElementRef, private translateService: TranslateService, private calendarService: CalendarService) {
  }

  @HostListener('window:resize', ['$event'])
  // tslint:disable-next-line:typedef
  onResize(event) {
    this.toggleListButtons();
  }

  ngOnInit(): void {
    this.initCalendar2();
    this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      this.updateCalendarLocale(this.translateService.currentLang);
    });
  }

  ngAfterViewInit(): void {
    this.calendar2.render();
    this.addCustomButtonClasses();
    this.toggleListButtons();
  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
    if (this.calendar2) {
      this.calendar2.destroy();
    }
  }

  private generateICS(eventDetails: { title: string; start: Date; end: Date; }): string {
    const start = eventDetails.start.toISOString().replace(/-|:|\.\d+/g, '');
    const end = eventDetails.end.toISOString().replace(/-|:|\.\d+/g, '');

    return `
BEGIN:VEVENT
DTSTART:${start}
DTEND:${end}
SUMMARY:${eventDetails.title}
END:VEVENT
    `.trim();
  }

  private downloadSingleEvent(event: any): void {
    const eventDetails = {
      title: event.title,
      start: event.start,
      end: event.end
    };

    const icsContentForSingleEvent = `
BEGIN:VCALENDAR
VERSION:2.0
${this.generateICS(eventDetails)}
END:VCALENDAR
    `.trim();

    this.downloadICS(icsContentForSingleEvent, `${event.title}.ics`);
  }

  private downloadICS(icsContent: string, filename: string): void {
    const blob = new Blob([icsContent], {type: 'text/calendar'});

    // Create an anchor element with a download attribute
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;

    // Trigger a click event on the anchor element
    downloadLink.dispatchEvent(new MouseEvent('click'));

    // Cleanup
    URL.revokeObjectURL(downloadLink.href);
  }

  // tslint:disable-next-line:typedef
//   public downloadEntireCalendar() {
//     const events = this.calendar2.getEvents();
//     let allEventsICS = '';
//
//     events.forEach(event => {
//       const eventDetails = {
//         title: event.title,
//         start: event.start,
//         end: event.end
//       };
//       allEventsICS += this.generateICS(eventDetails);
//     });
//
//     const fullICSContent = `
// BEGIN:VCALENDAR
// VERSION:2.0
// ${allEventsICS}
// END:VCALENDAR
//     `.trim();
//
//     this.downloadICS(fullICSContent, 'FullCalendar.ics');
//   }

  // tslint:disable-next-line:typedef
  public downloadEntireCalendar() {
    const events = this.calendar2.getEvents();
    let allEventsICS = '';

    events.forEach(event => {
      const eventDetails = {
        title: event.title,
        start: event.start,
        end: event.end
      };
      allEventsICS += this.generateICS(eventDetails) + '\n'; // Add a newline after each event
    });

    const fullICSContent = `
BEGIN:VCALENDAR
VERSION:2.0
${allEventsICS}
END:VCALENDAR
    `.trim();

    this.downloadICS(fullICSContent, 'FullCalendar.ics');
  }

// tslint:disable-next-line:align
  updateCalendarLocale(lang: string): void {
    this.calendar2.setOption('locale', lang);
  }

  private toggleListButtons(): void {
    const isMobile = window.innerWidth < 768;
    const listButtons = ['listDay', 'listWeek', 'listMonth', 'listYear'];

    listButtons.forEach(button => {
      const buttonElement = document.querySelector('.fc-' + button + '-button') as HTMLElement;
      if (buttonElement) {
        buttonElement.style.display = isMobile ? 'none' : 'inline-block';
      }
    });
  }

  private updateCalendarEvents(events): void {
    // Transform and check events
    const calendarEvents: CalendarEvent[] = events.map(event => ({
      title: event.title,
      start: event.start,
      end: event.end
    }));

    // console.log('Fetched events:', calendarEvents); // Check fetched events

    // Update the events in CalendarService
    this.calendarService.setEvents(calendarEvents);
  }

  private showCustomModal(): void {
    const modal = document.getElementById('customModal');
    if (modal) {
      modal.style.display = 'block';
    }
  }
  private showCustomModal2(): void {
    const modal = document.getElementById('customModal2');
    if (modal) {
      modal.style.display = 'block';
    }
  }
  private closeCustomModal(): void {
    const modal = document.getElementById('customModal');
    if (modal) {
      modal.style.display = 'none';
      modal.parentNode.removeChild(modal);
    }
  }
  private closeCustomModal2(): void {
    const modal = document.getElementById('customModal2');
    if (modal) {
      modal.style.display = 'none';
      modal.parentNode.removeChild(modal);
    }
  }
  private initCalendar2(): void {
    let initialView = 'dayGridMonth'; // Default initial view

    // Check if the screen size is small (max-width: 767px)
    if (window.innerWidth <= 767) {
      initialView = 'listMonth'; // Set initial view to 'listMonth' for small devices
    }
    this.calendar2 = new Calendar(document.getElementById('calendar2'), {
      // tslint:disable-next-line:typedef
      // Custom event content rendering function
      eventClick: (info) => {
        info.jsEvent.preventDefault();

        // Create a custom modal or dialog with three buttons: Download Event, Download Calendar, and Cancel

        const modalContent2 = `
<div id="customModal2" style="display: none; color: #81291B; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: white; padding: 20px; border: 1px solid #ccc; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); border-radius: 5px; z-index: 100; text-align: center;">

    <p>${this.translateService.instant('blogText170')}</p>
    <br>
    <div style="display: flex; justify-content: center;">
        <!-- Button container with flexbox -->
        <button id="downloadCalendarButton2" style="background-color: #EBD47B; color: #81291B; padding: 15px; margin: 0 10px; border: none; border-radius: 5px; cursor: pointer;">${this.translateService.instant('Ya')}</button>
        <button id="cancelButton2" style="background-color: #EBD47B; color: #81291B; padding: 15px; margin: 0 10px; border: none; border-radius: 5px; cursor: pointer;">${this.translateService.instant('Nein')}</button>
    </div>
</div>
`;


// Add media query for small devices
        const smallDeviceStyles = `
@media only screen and (max-width: 767px) {
    #customModal2 {
        width: 80%; /* Adjust as needed */
    }

    /* Increase spacing between buttons on small devices */
    #downloadEventButton,
    #downloadCalendarButton2,
    #cancelButton {
        margin-bottom: 15px;
    }

    /* Add additional styles for small devices if needed */
}
`;


        const styleTag = document.createElement('style');
        styleTag.appendChild(document.createTextNode(smallDeviceStyles));
        document.head.appendChild(styleTag);
        // Append modal content to the body
        document.body.insertAdjacentHTML('beforeend', modalContent2);

        // Get references to the buttons
        // const downloadEventButton = document.getElementById('downloadEventButton');
        const downloadCalendarButton2 = document.getElementById('downloadCalendarButton2');
        const cancelButton2 = document.getElementById('cancelButton2');

        // Add click event listeners to the buttons
        // downloadEventButton.addEventListener('click', () => {
        //     this.downloadSingleEvent(info.event);
        //     this.closeCustomModal2();
        // });

        downloadCalendarButton2.addEventListener('click', () => {
          this.downloadEntireCalendar();
          this.closeCustomModal2();
        });

        cancelButton2.addEventListener('click', () => {
          // Additional logic for canceling the download, if needed
          this.closeCustomModal2();
        });

        // Show the modal
        this.showCustomModal2();
      },
      now: undefined,
      eventsSet: (events) => this.updateCalendarEvents(events),
      plugins: [dayGridPlugin, listPlugin, googleCalendarPlugin],
      initialView,
      googleCalendarApiKey: 'AIzaSyDhZv2qrOxH4_AFS4t7Smurws_XyB9cDwM',
      navLinks: false,
      locales: allLocales,
      //  views: {
      //      listDay: {buttonText: this.translateService.instant('LIST_DAY', this.lang)},
      //      listWeek: {buttonText: this.translateService.instant('LIST_WEEK', this.lang)},
      //      listMonth: {buttonText: this.translateService.instant('LIST_MONTH', this.lang)},
      //      listYear: {buttonText: this.translateService.instant('LIST_YEAR', this.lang)}
      // },
      locale: this.translateService.currentLang,
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next'
      },
      eventSources: [
        {
          googleCalendarId: 'd9f333fb8210130d7dfd792b7c3553d25b9e24b75eaaa69e197ae08b90712900@group.calendar.google.com',
        }
      ]
    });
  }
  private addCustomButtonClasses(): void {
    const headerElement = this.elementRef.nativeElement.querySelector('.fc-header-toolbar');
    if (headerElement) {
      const prevButton = headerElement.querySelector('.fc-prev-button');
      const nextButton = headerElement.querySelector('.fc-next-button');
      if (prevButton) {
        prevButton.classList.add('main-btn4');
      }
      if (nextButton) {
        nextButton.classList.add('main-btn4');
      }
    }
  }
}
