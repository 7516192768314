import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Testimonials} from '../data/model/testimonials.model';
import {SiteSetting} from '../data/model/siteSetting.model';
import {environment} from '../../environments/environment';
import {SiteSittingService} from './site-sitting.service';

@Injectable({
    providedIn: 'root'
})
export class TestimonialsFromBackendService {
    lang = localStorage.getItem('lang') || 'de';

    private apiUrl: string = environment.apiUrl;
    private urlSiteSetting = this.apiUrl + '/siteSetting';
    private urlALLTestimonials = this.apiUrl + '/testimonial';
    private urlTestimonials = this.apiUrl + '/testimonial/testimonial';
    private urlQuotes = this.apiUrl + '/testimonial/quotes';

    private urlBanner = this.apiUrl + '/testimonial/banner';
    public stampName = '';

    constructor(private httpClient: HttpClient, private siteSittingService: SiteSittingService) {
    }

    public getTestimonials(): any {
        return this.httpClient.get<Testimonials[]>(this.urlTestimonials + '/' + this.lang + '/');
    }

    public getTestimonialsByGroup(group: string): any {
        return this.httpClient.get<Testimonials[]>(this.urlALLTestimonials + '/' + group + '/' + this.lang + '/');
    }

    // // tslint:disable-next-line:typedef
    // async getSitesitting(id: string) {
    //     return this.httpClient.get<SiteSetting>(this.urlSiteSetting + id);
    // }

    // tslint:disable-next-line:typedef
    async getBannerByStamp2() {
        (await this.siteSittingService.getSitesitting('/1')).subscribe((data: SiteSetting) => {
            this.stampName = data.stampName;
        });
        return this.httpClient.get<Testimonials[]>(this.urlBanner + '/' + this.stampName + '/' + this.lang + '/');
    }

    public updateSiteSettingBannerStamp(stampName: SiteSetting): any {
        // alert(this.httpClient.put(this.urlSiteSettingStampName, stampName).forEach(e => alert(e)));
        return this.httpClient.put(this.urlSiteSetting + '/1', stampName).subscribe(data => {
            alert('Banner change to ' + stampName.stampName);
        });
    }

    public updateSiteSettingHPageBanner(hPageBanner: SiteSetting): any {
        // alert(this.httpClient.put(this.urlSiteSetting , stampName).forEach(e => alert(e)));
        return this.httpClient.put(this.urlSiteSetting + '/2', hPageBanner).subscribe(data => {
            alert('Banner change to ' + hPageBanner.hPageBanner);
        });
    }

    public getQuotes(): any {
        return this.httpClient.get<Testimonials[]>(this.urlQuotes + '/' + this.lang + '/');
    }

    // tslint:disable-next-line:typedef
    postNewTestimonial(newTestimonials: Testimonials) {
        return this.httpClient.post(this.urlALLTestimonials, newTestimonials);
    }

    public getALLTestimonials(): any {
        return this.httpClient.get<Testimonials[]>(this.urlALLTestimonials + '/' + this.lang + '/');
    }

    // tslint:disable-next-line:typedef
    getTestimonialForSearch(searchText: string) {
        return this.httpClient.get<Testimonials[]>(this.urlALLTestimonials + '/commet/' + searchText + '/' + this.lang + '/');
    }

    // tslint:disable-next-line:typedef
    getTestimonialForSearchById(id: number) {
        return this.httpClient.get<Testimonials[]>(this.urlALLTestimonials + '/id/' + id);
    }

    deleteTestimonial(id: number): void {
        this.httpClient.delete(this.urlALLTestimonials + '/' + id).subscribe(data => {
            // console.log(data);
        });
    }

    // tslint:disable-next-line:typedef
    updateTestimonial(id: number, uTestimonial: Testimonials) {
        alert('updateTestimonial');
        return this.httpClient.put(this.urlALLTestimonials + '/' + id, uTestimonial);
    }

    public getQuotesByStamp(stamp: string): any {
        return this.httpClient.get<Testimonials[]>(this.urlQuotes + '/' + stamp + '/' + this.lang + '/');
    }

}
