import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {environment} from '../environments/environment.prod';
import {SiteSittingService} from './service/site-sitting.service';
import {Buch} from './data/model/buch.model';
import {WachsenImGeistModule} from './components/pages/wachsen-im-geist/wachsen-im-geist.module';


const routes: Routes = [
    // Home
    {
        path: '',
        loadChildren: () => import('./components/pages/home-v2/home-v2.module').then(m => m.HomeV2Module),
        data: {breadcrumb: 'Homepage'},
    },
    {
        path: 'home-v2',
        loadChildren: () => import('./components/pages/home-v2/home-v2.module').then(m => m.HomeV2Module),
        data: {breadcrumb: 'Homepage'}
    },
    // Pages
    {
        path: 'about',
        loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule),
        data: {breadcrumb: 'About Us'}
    },
    {
        path: 'team', loadChildren: () => import('./components/pages/team/team.module').then(m => m.TeamModule),
        data: {breadcrumb: 'Geistliche'}
    },
    {
        path: 'team-details/:id',
        loadChildren: () => import('./components/pages/team-details/team-details.module').then(m => m.TeamDetailsModule),
        data: {breadcrumb: 'Über Geistliche'}
    },
    {
        path: 'betreuer-details/:id',
        loadChildren: () => import('./components/pages/betreuer-details/betreuer-details.module').then(m => m.BetreuerDetailsModule),
        data: {breadcrumb: 'Über Betreuer'}
    },
    // { path: 'careers', loadChildren: () => import('./components/pages/careers/careers.module').
    // then(m => m.CareersModule), data: { breadcrumb: 'Careers' } },
    {path: 'faqs', loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule), data: {breadcrumb: 'FAQ\'s'}},
    // Services
    {
        path: 'services',
        loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule),
        data: {breadcrumb: 'Dienstleistungen'}
    },
    {
        path: 'services-v2',
        loadChildren: () => import('./components/pages/services-v2/services-v2.module').then(m => m.ServicesV2Module),
        data: {breadcrumb: 'Services'}
    },
    {
        path: 'service-details/:id',
        loadChildren: () => import('./components/pages/service-details/service-details.module').then(m => m.ServiceDetailsModule),
        data: {breadcrumb: 'Service Details'}
    },
    {
        path: 'betreuer-grid/:post',
        loadChildren: () => import('./components/pages/betreuer-grid/betreuer-grid.module').then(m => m.BetreuerGridModule),
        data: {breadcrumb: 'Beratungsanbieter'}
    },
    // Blog
    {
        path: 'blog/cat/:catId',
        loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule),
        data: {breadcrumb: 'Blog'}
    },
    {
        path: 'blog/tag/:tagId',
        loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule),
        data: {breadcrumb: 'Blog'}
    },
    {
        path: 'blog/author/:authorId',
        loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule),
        data: {breadcrumb: 'Blog'}
    },
    {
        path: 'blog-grid',
        loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule),
        data: {breadcrumb: 'Nachrichten'}
    },
    {
        path: 'blog-standard',
        loadChildren: () => import('./components/pages/blog-standard/blog-standard.module').then(m => m.BlogStandardModule),
        data: {breadcrumb: 'Blog Standard'}
    },
    {
        path: 'blog-details/:id',
        loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule),
        data: {breadcrumb: 'Details-der-Nachrichten'}
    },
    // Lernmaterial
    {
        path: 'lernmaterial/cat/:catId',
        loadChildren: () => import('./components/pages/lernmaterial-grid/lernmaterial-grid.module').then(m => m.LernmaterialGridModule),
        data: {breadcrumb: 'Lernmaterial'}
    },
    {
        path: 'lernmaterial/tag/:tagId',
        loadChildren: () => import('./components/pages/lernmaterial-grid/lernmaterial-grid.module').then(m => m.LernmaterialGridModule),
        data: {breadcrumb: 'Lernmaterial'}
    },
    {
        path: 'lernmaterial/author/:authorId',
        loadChildren: () => import('./components/pages/lernmaterial-grid/lernmaterial-grid.module').then(m => m.LernmaterialGridModule),
        data: {breadcrumb: 'Lernmaterial'}
    },
    {
        path: 'lernmaterial-grid',
        loadChildren: () => import('./components/pages/lernmaterial-grid/lernmaterial-grid.module').then(m => m.LernmaterialGridModule),
        data: {breadcrumb: 'Lernmaterial'}
    },
    {
        path: 'lernmaterial-details/:id',
        loadChildren: () => import('./components/pages/lernmaterial-details/lernmaterial-details.module')
            .then(m => m.LernmaterialDetailsModule),
        data: {breadcrumb: 'Details-der-Lernmaterial'}
    },
     // Bibilothek
    {
        path: 'bibilothek/cat/:catId',
        loadChildren: () => import('./components/pages/bibilothek/bibilothek.module').then(m => m.BibilothekModule),
        data: {breadcrumb: 'Bibilothek'}
    },
    {
        path: 'bibilothek/tag/:tagId',
        loadChildren: () => import('./components/pages/bibilothek/bibilothek.module').then(m => m.BibilothekModule),
        data: {breadcrumb: 'Bibilothek'}
    },
    {
        path: 'bibilothek',
        loadChildren: () => import('./components/pages/bibilothek/bibilothek.module').then(m => m.BibilothekModule),
        data: {breadcrumb: 'Bibilothek'}
    },
    {
        path: 'buch-details/:id',
        loadChildren: () => import('./components/pages/buch-details/buch-details.module').then(m => m.BuchDetailsModule),
        data: {breadcrumb: 'Buch Details'}
    },
    // Contact
    {
        path: 'contact',
        loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule),
        data: {breadcrumb: 'Kontaktiere uns'}
    },
    // metropolie
    {
        path: 'metropolie',
        loadChildren: () => import('./components/pages/metropolie/metropolie.module').then(m => m.MetropolieModule),
        data: {breadcrumb: 'Metropolit'}
    },
    // metropolie-geschichte
    {
        path: 'metropolie-geschichte',
        loadChildren: () => import('./components/pages/metropolie-geschichte/metropolie-geschichte.module')
            .then(m => m.MetropolieGeschichteModule),
        data: {breadcrumb: 'Die Geschichte der Metropolie'}
    },
    // antiochenisch-kirche
    {
        path: 'antiochenisch-kirche',
        loadChildren: () => import('./components/pages/antiochenisch-kirche/antiochenisch-kirche.module')
            .then(m => m.AntiochenischKircheModule),
        data: {breadcrumb: 'antiochenisch-kirche'}
    },
// wien
    {path: 'wien', loadChildren: () => import('./components/pages/wien/wien.module').then(m => m.WienModule), data: {breadcrumb: 'Wien'}},
// innsbruck
    {
        path: 'innsbruck',
        loadChildren: () => import('./components/pages/innsbruck/innsbruck.module').then(m => m.InnsbruckModule),
        data: {breadcrumb: 'Innsbruck'}
    },
    // mission
    {
        path: 'mission',
        loadChildren: () => import('./components/pages/mission/mission.module').then(m => m.MissionModule),
        data: {breadcrumb: 'Mission'}
    },
    // dashboard        = true;
    {
        path: 'dashboard',
        // redirectTo: 'login',
        //
        // loadChildren: () => {
        //     // @ts-ignore
        //     return this. getLoginFlage() ?
        //         import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) :
        //         import('./components/pages/login/login.module').then(m => m.LoginModule);
        // },
        loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {breadcrumb: 'Dashboard'}
    },
    // taufe
    {
        path: 'taufe',
        loadChildren: () => import('./components/pages/taufe/taufe.module').then(m => m.TaufeModule),
        data: {breadcrumb: 'Die Taufe'}
    },
    // ehe
    {
        path: 'ehe',
        loadChildren: () => import('./components/pages/ehe/ehe.module').then(m => m.EheModule),
        data: {breadcrumb: 'Ehe'}
    },
    // ausschusse
    {
        path: 'ausschusse',
        loadChildren: () => import('./components/pages/ausschusse/ausschusse.module').then(m => m.AusschusseModule),
        data: {breadcrumb: 'Ausschusse'}
    },
    // impressum
    {
        path: 'impressum',
        loadChildren: () => import('./components/pages/impressum/impressum.module').then(m => m.ImpressumModule),
        data: {breadcrumb: 'Impressum'}
    },
    // datenschutz
    {
        path: 'datenschutzerklärung',
        loadChildren: () => import('./components/pages/datenschutz/datenschutz.module').then(m => m.DatenschutzModule),
        data: {breadcrumb: 'Datenschutzerklärung'}
    },
    // login
    {
        path: 'login',
        loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule),
        data: {breadcrumb: 'Login'}
    },
    {
        path: 'calender',
        loadChildren: () => import('./components/pages/calendar/calendar.module').then(m => m.CalendarModule),
        data: {breadcrumb: 'Calendar'}
    },
    {
        path: 'Wachsen-im-Geist',
        loadChildren: () => import('./components/pages/wachsen-im-geist/wachsen-im-geist.module').then(m => m.WachsenImGeistModule),
        data: {breadcrumb: 'Wachsen im Geist'}
    },
    {
        path: 'calender2',
        loadChildren: () => import('./components/pages/calendar2/calendar2.module').then(m => m.Calendar2Module),
        data: {breadcrumb: 'Calendar2'}
    },
    {
        path: 'gallery',
        loadChildren: () => import('./components/pages/gallery/gallery.module').then(m => m.GalleryModule),
        data: {breadcrumb: ' Gallery'}
    },
    {path: '**', redirectTo: 'home-v2', pathMatch: 'full'},

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}), RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
    private flag: any;
    constructor(private siteSittingService: SiteSittingService) {
    }

    // // tslint:disable-next-line:typedef
    // public getLoginFlage(){
    //     alert(this.siteSittingService.getLoginFlag());
    //     return this.siteSittingService.getLoginFlag();
    // }
}

// path: 'betreuer-grid/:post',
