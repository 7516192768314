<div class="container">
    <div class="canvas-container">
        <canvas #canvas></canvas>
    </div>
    <br><br>
<!--    <input [(ngModel)]="selectedStyle">-->
    <div class="card text-actions">
        <input class="text-input" [(ngModel)]="textToAdd" placeholder="{{ 'Hier Text eingeben' | translate }}">
    </div>

    <div class="card text-samples2">
    <button class="main-btn" (click)="mergeRandomImageAndText()">{{'Neuer Inhalt' | translate}}</button>
        <button class="main-btn" (click)="applyTextFromInput()">{{'Benutzerdefinierter Text' | translate}}</button>

</div>
    <!-- Text Sample Buttons -->  <hr>
    <div class="card text-samples">
        <button class="main-btn" (click)="selectSampHeiligenn()">{{'Worte der Heiligen' | translate}}</button>
        <button class="main-btn" (click)="selectSampBible()">{{'Aus der Bibel' | translate}}</button>
        <button class="main-btn" (click)="selectSampLicht()">{{'Glanz und Licht' | translate}}</button>
        <button class="main-btn" (click)="selectSampOstern()">{{'Ostern' | translate}}</button>
<!--        <button class="main-btn" (click)="selectSampWeihnachten()">{{'Weihnachten-Neujahr' | translate}}</button>-->
<!--        <button class="main-btn" (click)="selectSampInfo()">{{'Info' | translate}}</button>-->
<!--        <button class="main-btn" (click)="selectSampFrage()">{{'eine Frage' | translate}}</button>-->
            </div>
    <hr>
    <!-- Image Manipulation and Sharing Actions -->
    <div class="card image-actions">
<!--        <button class="main-btn" (click)="toggleShadowBox()">Schatten umschalten</button>-->
        <button class="main-btn" (click)="toggleIconDrawing2()">{{'Logo umschalten' | translate}}</button>
        <button class="main-btn" (click)="toggleIconDrawing()">{{'Linkes Bild umschalten' | translate}}</button>
        <button class="main-btn" (click)="toggleBottomText()">{{'name umschalten' | translate}}</button>
        <button class="main-btn" (click)="resetCanvas()">{{'zurücksetzen' | translate}}</button>
    </div>
    <hr>
    <div class="card custom-actions">
        <input type="file" #fileInput (change)="handleFileInput($event)" style="display: none;" accept="image/*">
        <button class="main-btn" (click)="fileInput.click()">{{'Benutzerdefinierte Fotos' | translate}}</button>
        <button class="main-btn" (click)="changeStyle()">{{'Stil ändern' | translate}}</button>
        <button class="main-btn" (click)="applyRandomTextFromList()">{{'Text ändern' | translate}}</button>
        <button class="main-btn" (click)="changeBackgroundImageRandomly()">{{'Bild ändern' | translate}}</button>
    </div>
    <hr>
    <div class="card share">


        <button class="icon-btn" (click)="incresSize()">
            <i class="img-bg-rrr fa fa-plus"></i>
        </button>
        <button class="icon-btn" (click)="decresSize()">
            <i class="img-bg-rrr fa fa-minus"></i>
        </button>
        <div>
            <input type="color" [(ngModel)]="selectedColor" (change)="onColorChange()">
            <button (click)="generateRandomGradient()">Random Gradient</button>
        </div>
        <canvas #canvasElement width="200" height="200"></canvas>
        <hr> <hr>
        <button class="icon-btn" (click)="shareOrCopyImage()">
            <i class="img-bg-rrr fas fa-share-square"></i>
        </button>
        <button class="icon-btn" (click)="downloadImage()">
            <i class="img-bg-rrr fas fa-download"></i>
        </button>
        <button class="icon-btn" (click)="copyImageToClipboard()">
            <i class="img-bg-rrr fas fa-copy"></i>
        </button>
  </div>



