import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
    public path: string;
    public path2: string;
    public txt: string;

    constructor() {
    }

    ngOnInit(): void {
        // this.txt = parent.location.pathname.match('[\\/][^\\/\\/]*').toString();
        this.txt = parent.location.href.match('[^#\\/]*$').toString();
        if (parent.location.href.includes('blog-details')) {
            this.path = 'assets/img/breadcrumb/blog-details.webp';
        } else if (parent.location.href.includes('buch-details')) {
            this.path = 'assets/img/breadcrumb/buch-details.webp';
        } else if (parent.location.href.includes('betreuer-grid')) {
            this.path = 'assets/img/breadcrumb/betreuer-grid.webp';
        } else if (parent.location.href.includes('blog/cat/')) {
            this.path = 'assets/img/breadcrumb/blog.webp';
        }else if (parent.location.href.includes('bibilothek/cat/')) {
            this.path = 'assets/img/breadcrumb/buch-details.webp';
        }else if (parent.location.href.includes('blog/tag/')) {
            this.path = 'assets/img/breadcrumb/blog.webp';
        }else if (parent.location.href.includes('bibilothek/tag/')) {
            this.path = 'assets/img/breadcrumb/buch-details.webp';
        }else if (parent.location.href.includes('lernmaterial/tag/')) {
            this.path = 'assets/img/breadcrumb/lernmaterial-grid.webp';
        }else if (parent.location.href.includes('lernmaterial/cat/')) {
            this.path = 'assets/img/breadcrumb/lernmaterial-grid.webp';
        }    else if (parent.location.href.includes('betreuer-details')) {
    this.path = 'assets/img/breadcrumb/betreuer-details.webp';
}
        else if (parent.location.href.includes('Wachsen')) {
            this.path = 'assets/img/breadcrumb/Wachsen-im-Geist.webp';
        }
                else {
            this.path = 'assets/img/breadcrumb/' + this.txt + '.webp';
        }
        // alert(parent.location.href.match('[^#\\/]*$').toString());
    }
}
