<!-- sidebar -->
<div class="sidebar">
    <!-- Search Widget -->
    <!--    <div class="widget search-widget">-->
    <!--        <form>-->
    <!--            <input type="search" [(ngModel)]="serachTxt"-->
    <!--                   placeholder="Search here"-->
    <!--                   (input)="getBlogFromBackendForSearch() "-->
    <!--                   autofocus-->
    <!--                   id="searchInput">-->
    <!--            <button type="submit" (submit)="getBlogFromBackendForSearch()"><i class="far fa-search"></i></button>-->
    <!--        </form>-->
    <!--    </div>-->
    <!-- Cat Widget -->
    <div class="widget cat-widget">
        <h4 class="widget-title img-bg-rrr">{{'Kategorie'|translate}}</h4>
        <ul>
            <li *ngFor="let item of blogcategory">
                <a routerLink="/blog/cat/{{item.id}}" style="color:#EBD47B;">{{item.title | translate}} <span><i
                        class="far fa-angle-right"></i></span></a>
            </li>
        </ul>
    </div>
    <!-- Recent Post Widget -->
    <!--    <div class="widget recent-post-widget">-->
    <!--        <h4 class="widget-title img-bg-rrr">Recent News</h4>-->
    <!--        <div class="post-loops">-->
    <!--            <div class="single-post" *ngFor="let item of blog | slice:0:3">-->
    <!--                <div class="post-thumb">-->
    <!--                    <img [src]="item.image" [alt]="item.title">-->
    <!--                </div>-->
    <!--                <div class="post-desc">-->
    <!--                    <span class="date"><i class="far fa-calendar-alt"></i>{{item.postdate}}</span>-->
    <!--                    <a routerLink="/blog-details/{{item.id}}">{{item.title | slice:0:30}}... </a>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <!-- Popular Tag Widget -->
    <div class="widget popular-tag-widget">
        <h4 class="widget-title img-bg-rrr">{{'Beliebte Tags'|translate}}</h4>
        <div class="spa">
            <a routerLink="/blog/tag/{{item.id}}" *ngFor="let item of blogtags">{{item.title | translate}}</a>
        </div>
    </div>
    <!--     CTA Widget -->
    <!--    <div class="widget cta-widget" style="background-image: url(assets/img/sidebar/cta.webp);">-->
    <!--        <h4 class="title">Need Any Consultations</h4>-->
    <!--        <a href="#" class="main-btn">Send Message</a>-->
    <!--    </div>-->
</div>
