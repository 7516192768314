import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import WebFont from 'webfontloader';
import {AuthorFromBackendService} from '../../../service/author-from-backend.service';
import {TestimonialsFromBackendService} from '../../../service/testimonials-from-backend.service';
import {Author} from '../../../data/model/author.model';
import {Testimonials} from '../../../data/model/testimonials.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-image-popup',
    templateUrl: './image-popup.component.html',
    styleUrls: ['./image-popup.component.css']
})
export class ImagePopupComponent implements AfterViewInit, OnInit {
    public selectedStyle: any;

    constructor(private authorFromBackendService: AuthorFromBackendService,
                private testimonialsFromBackendService: TestimonialsFromBackendService, private translate: TranslateService) {
    }

    @ViewChild('canvas', {static: false}) canvasRef: ElementRef<HTMLCanvasElement>;
    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
    @ViewChild('canvasElement') canvasElement: ElementRef<HTMLCanvasElement>;
    public selectedColor = '#ffffff'; // Default color
    private fillStyle: string | CanvasGradient = '#ffffff'; // Can be a color or a gradient

    stamp = '';
    public selectedQuotes: Testimonials;
    public quotes: any[] = [];
    public authors: Author[];
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    texts1 = [];
    drawIcon = false;
    drawIcon2 = false;
    selectedImage: string;
    textToAdd: string;
    lang = localStorage.getItem('lang');
    randomFont: string;
    addShadowBox = false;
    bottomText = 'Your Default Text';
    showBottomText = false;
    fontsDE = ['Nunito Sans', 'Source Sans Pro', 'Qwenzy'];
    fontsAr = ['Noto Sans Arabic', 'Lateef', 'Scheherazade', 'Amiri', 'Tajawal', 'Mada', 'Jomhuria', 'El Messiri', 'Reem Kufi', 'Lalezar'];
    stampList = ['Heiligen', 'Bible', 'Weihnachten', 'Info', 'Frage', 'Licht', 'Ostern'];
    isWebShareAvailable = false;
    textSize = 26;
    lineHeight = 40;
        ngAfterViewInit(): void {
        this.canvas = this.canvasRef.nativeElement;
        this.ctx = this.canvas.getContext('2d');
        this.applyFillStyle();
        // this.ctx.fillStyle = 'black';
        this.ctx.fillText('Test Text', 50, 50);
    }

    // public getRandomGeneralImage(): void {
    //     const randomIndex = Math.floor(Math.random() * 101) + 1;
    //     this.selectedImage = 'assets/img/popup/p' + randomIndex + '.jpg';
    // }


    public getRandomGeneralImage(): void {

        let randomIndex = 2;
        switch (this.stamp) {
            case 'Heiligen':
                randomIndex = Math.floor(Math.random() * 120) + 1;
                this.selectedImage = 'assets/img/popup/p' + randomIndex + '.jpg';
                break;
            case 'Bible':
                randomIndex = Math.floor(Math.random() * 120) + 1;
                this.selectedImage = 'assets/img/popup/p' + randomIndex + '.jpg';
                break;
            case 'Info':
                randomIndex = Math.floor(Math.random() * 50) + 1;
                this.selectedImage = 'assets/img/popup/i' + randomIndex + '.jpg';
                break;
            case 'Frage':
                randomIndex = Math.floor(Math.random() * 50) + 1;
                this.selectedImage = 'assets/img/popup/f' + randomIndex + '.jpg';
                break;
            case 'Licht':
                randomIndex = Math.floor(Math.random() * 50) + 1;
                this.selectedImage = 'assets/img/popup/l' + randomIndex + '.jpg';
                break;
            case 'Weihnachten':
                randomIndex = Math.floor(Math.random() * 50) + 1;
                this.selectedImage = 'assets/img/popup/w' + randomIndex + '.jpg';
                break;
            case 'Ostern':
                randomIndex = Math.floor(Math.random() * 50) + 1;
                this.selectedImage = 'assets/img/popup/o' + randomIndex + '.jpg';
                break;
            default:
                this.selectedImage = 'assets/img/popup/p' + randomIndex + '.jpg';
        }
    }

    selectImage(image: string): void {
        this.selectedImage = image;
        this.updateCanvas();
    }

    applyTextFromInput(): void {
        this.stamp = this.getRandomItem(this.stampList);
        this.getRandomGeneralImage();
        this.updateCanvas();
    }


    getRandomFont(): void {
        const fontList = this.lang === 'ar' ? this.fontsAr : this.fontsDE;
        this.randomFont = this.getRandomItem(fontList);
    }

    // loadFont(): Promise<void> {
    //     return new Promise((resolve, reject) => {
    //         WebFont.load({
    //             google: {families: [this.randomFont]},
    //             active: resolve,
    //             inactive: () => {
    //                 console.error('Failed to load font:', this.randomFont);
    //                 reject(new Error('Failed to load font'));
    //             }
    //         });
    //     });
    // }
    loadFont(): Promise<void> {
        return new Promise((resolve) => {
            resolve();
        });
    }

    loadImage(src: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
            img.onerror = (error) => {
                console.error('Error loading image:', src, error);
                reject(error);
            };
        });
    }

    async drawOnCanvas(img: HTMLImageElement): Promise<void> {
        if (!this.canvas || !this.ctx) {
            throw new Error('Canvas or context not initialized');
        }

        this.canvas.width = 640;
        this.canvas.height = 341;

        // Define the rounded rectangle path
        const radius = 40; // Adjust this value to change the roundness of the corners
        this.ctx.beginPath();
        this.ctx.moveTo(radius, 0);
        this.ctx.arcTo(this.canvas.width, 0, this.canvas.width, this.canvas.height, radius);
        this.ctx.arcTo(this.canvas.width, this.canvas.height, 0, this.canvas.height, radius);
        this.ctx.arcTo(0, this.canvas.height, 0, 0, radius);
        this.ctx.arcTo(0, 0, this.canvas.width, 0, radius);
        this.ctx.closePath();

        // Clip the canvas to the rounded rectangle path
        this.ctx.clip();

        // Draw the image inside the clipped area
        this.ctx.drawImage(img, 0, 0, 640, 341);
        this.ctx.font = `${this.textSize}px ${this.randomFont}`;
        this.ctx.textAlign = 'center';
        this.ctx.textBaseline = 'middle';


        // Only set the style if it hasn't been set before
        if (!this.selectedStyle) {
            this.selectedStyle = Math.floor(Math.random() * 4) + 1;
        }
        switch (this.selectedStyle) {
            case 1:
                this.applyStyle1();
                break;
            case 2:
                this.applyStyle2();
                break;
            case 3:
                this.applyStyle3();
                break;
            case 4:
                this.applyStyle4();
                break;
        }
        this.ctx.fillStyle =  this.fillStyle ;
        this.wrapText(this.ctx, this.textToAdd, this.canvas.width / 2, this.canvas.height / 2, 600, this.lineHeight);
    }

    async updateCanvas(): Promise<void> {
        if (!this.canvas) {
            throw new Error('Canvas not initialized');
        }
        // this.getRandomFont();
        await Promise.all([
            this.loadFont(),
            this.loadImage(this.selectedImage)
        ]).then((results) => {
            const img = results[1] as HTMLImageElement;
            this.drawOnCanvas(img);
        }).catch(error => {
            console.error('Error updating canvas:', error);
        });
    }

    public wrapText(context, text, x, y, maxWidth, lineHeight): void {
        // this.setShadow(context, 'black', 2, 1, 1);
        // this.fillTextMultipleTimes(context, text, x, y, maxWidth, lineHeight);
        //
        // this.setShadow(context, 'blue', 25, 0, 0);
        // this.fillTextMultipleTimes(context, text, x, y, maxWidth, lineHeight);

        // context.shadowColor = 'transparent';
        const lines = this.getLines(context, text, maxWidth);
        if (this.addShadowBox && lines.length > 0) {
            this.drawShadowBox(context, text, x, y, lineHeight, lines.length); // Pass the number of lines to the function
        }
        this.fillTextMultipleTimes(context, text, x, y, maxWidth, lineHeight);
    }

    getLines(context, text, maxWidth): string[] {
        const words = text.split(' ');
        let line = '';
        const lines = [];

        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                lines.push(line.trim());
                line = words[n] + ' ';
            } else {
                line = testLine;
            }
        }
        lines.push(line.trim());
        return lines;
    }

    setShadow(context, color, blur, offsetX, offsetY): void {
        context.shadowColor = color;
        context.shadowBlur = blur;
        context.shadowOffsetX = offsetX;
        context.shadowOffsetY = offsetY;
    }

    drawShadowBox(context, text, x, y, lineHeight, numberOfLines): void {
        const metrics = context.measureText(text);
        const padding = 10;
        const totalHeight = numberOfLines * lineHeight + 60;

        context.fillStyle = 'rgba(120,164,245,0.44)';
        context.fillRect(x - metrics.width / 2 - padding, y - totalHeight / 2 + 20, metrics.width + 2 * padding, totalHeight);
    }

    fillTextMultipleTimes(context, text, x, y, maxWidth, lineHeight): void {
        const words = text.split(' ');
        let line = '';

        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            } else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
    }

    // async shareOrCopyImage(): Promise<void> {
    //     this.redrawCanvas();
    //     // Check if the device supports the Web Share API
    //     if (navigator.share) {
    //         try {
    //             const blob = await this.canvasToBlob();
    //             const file = new File([blob], 'shareImage.png', {type: 'image/png'});
    //
    //             const shareData = {
    //                 title: 'My Image',
    //                 text: 'Check out this image!',
    //                 files: [file]
    //             };
    //
    //             await navigator.share(shareData);
    //         } catch (err) {
    //             console.error('Error sharing the image:', err);
    //         }
    //     } else {
    //         // If the device doesn't support sharing images, copy it to the clipboard
    //         try {
    //             await this.copyImageToClipboard();
    //         } catch (error) {
    //             console.error('Error copying image to clipboard:', error);
    //             alert('Failed to copy the image. Please try again.');
    //         }
    //     }
    // }
    async shareOrCopyImage(): Promise<void> {
        await this.redrawCanvas(); // Ensure canvas is fully drawn
        // Check if the device supports the Web Share API
        if (navigator.share) {
            try {
                const blob = await this.canvasToBlob();
                const file = new File([blob], 'shareImage.png', {type: 'image/png'});

                const shareData = {
                    title: 'My Image',
                    text: 'Check out this image!',
                    files: [file]
                };

                await navigator.share(shareData);
            } catch (err) {
                console.error('Error sharing the image:', err);
            }
        } else {
            // If the device doesn't support sharing images, copy it to the clipboard
            try {
                await this.copyImageToClipboard();
            } catch (error) {
                console.error('Error copying image to clipboard:', error);
                alert('Failed to copy the image. Please try again.');
            }
        }
    }

    // async copyImageToClipboard(): Promise<void> {
    //     this.redrawCanvas();
    //     try {
    //         const blob = await this.canvasToBlob();
    //         const item = new ClipboardItem({'image/png': blob});
    //         await navigator.clipboard.write([item]);
    //         alert('Image copied to clipboard. You can now paste it into your desired platform.');
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    async copyImageToClipboard(): Promise<void> {
        this.translate.use('this.lang');
        this.redrawCanvas();
        await new Promise(resolve => setTimeout(resolve, 100));
        if (!navigator.clipboard) {
            this.translate.get( 'Clipboard-API ist nicht verfügbar.' ).subscribe((translatedErrorMessage: string) => {
                alert(translatedErrorMessage);
            });
            return;
        }
        try {
            const dataUrl = this.canvas.toDataURL('image/png');
            const response = await fetch(dataUrl);
            const blob = await response.blob();
            if (!blob) {
                    this.translate.get( 'Keine Bilddaten zum Kopieren gefunden.' ).subscribe((translatedErrorMessage2: string) => {
                    alert(translatedErrorMessage2);
                });
                    return;
            }
            const item = new ClipboardItem({'image/png': blob});
            await navigator.clipboard.write([item]);
            this.translate.get( 'blogText167' ).subscribe((translatedMessage3: string) => {
                alert(translatedMessage3);
            });
        } catch (error) {
            alert(``);
            this.translate.get( 'blogText168' ).subscribe((translatedMessage4: string) => {
                alert(translatedMessage4);
            });
                   }
    }


    canvasToBlob(): Promise<Blob> {
        return new Promise((resolve, reject) => {
            this.canvas.toBlob((blob) => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error('Failed to convert canvas to blob'));
                }
            }, 'image/png');
        });
    }


    async downloadImage(): Promise<void> {
        try {
            if (!this.canvas && this.canvasRef) {
                this.initializeCanvas();
            }
            if (!this.selectedImage) {
                this.getRandomGeneralImage();
                this.textToAdd = this.selectedQuotes.comment;
                await this.updateCanvas();
                this.saveCanvasAsImage();
            } else {
                this.saveCanvasAsImage();
            }
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    }

    initializeCanvas(): void {
        this.canvas = this.canvasRef.nativeElement;
        this.ctx = this.canvas.getContext('2d');
    }

    saveCanvasAsImage(): void {
        const dataURL = this.canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = dataURL;
        downloadLink.download = 'result.png';
        downloadLink.click();
        // this.resetCanvas();
    }

    getRandomItem<T>(items: T[]): T {
        const randomIndex = Math.floor(Math.random() * items.length);
        return items[randomIndex];
    }

    resetCanvas(): void {
        if (!this.canvas || !this.ctx) {
            throw new Error('Canvas or context not initialized');
        }
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.textToAdd = '';
        this.textSize = 26;
    }

    measureTextWidth(context: CanvasRenderingContext2D, text: string, font: string): number {
        context.font = font;
        return context.measureText(text).width;
    }

    generateRandomTextStyle(): void {
        this.getRandomFont();
        const styles = [
            {
                fillStyle: 'gold',
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 8,
                shadowOffsetX: 4,
                shadowOffsetY: 4,
                font: this.randomFont
            },
            {
                fillStyle: 'white',
                shadowColor: 'rgba(0, 0, 0, 0.7)',
                shadowBlur: 10,
                shadowOffsetX: 5,
                shadowOffsetY: 5,
                font: this.randomFont
            },
            {
                fillStyle: 'lightgray',
                shadowColor: 'rgba(0, 0, 0, 0.4)',
                shadowBlur: 6,
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                font: this.randomFont
            },
            {
                fillStyle: 'silver',
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowBlur: 7,
                shadowOffsetX: 2,
                shadowOffsetY: 2,
                font: this.randomFont
            }
        ];

        const randomStyle = styles[Math.floor(Math.random() * styles.length)];

        this.ctx.fillStyle = randomStyle.fillStyle;
        this.ctx.shadowColor = randomStyle.shadowColor;
        this.ctx.shadowBlur = randomStyle.shadowBlur;
        this.ctx.shadowOffsetX = randomStyle.shadowOffsetX;
        this.ctx.shadowOffsetY = randomStyle.shadowOffsetY;
        this.ctx.font = randomStyle.font;
    }

    applyStyle4(): void {
        this.generateRandomTextStyle();
    }

    applyStyle1(): void {
        this.generateRandomTextStyle();
        const gradient = this.ctx.createLinearGradient(0, 0, this.canvas.width, 0);
        gradient.addColorStop(0, '#DED692');
        gradient.addColorStop(1, 'rgb(255,255,255)');
        this.ctx.fillStyle = gradient;
        this.ctx.shadowColor = 'black';
        this.ctx.shadowBlur = 15;
        this.ctx.shadowOffsetX = 5;
        this.ctx.shadowOffsetY = 5;
    }

    applyStyle2(): void {
        const metrics = this.ctx.measureText(this.textToAdd);
        const padding = 15; // زيادة الحشو قليلاً للحصول على مظهر أكثر فسحة
        const x = this.canvas.width / 2 - metrics.width / 2 - padding;
        const y = this.canvas.height / 2 - 33 / 2 - padding;
        const width = metrics.width + 2 * padding;
        const height = 33 + 2 * padding;
        const radius = 15;  // زيادة التقريب للحصول على حواف أكثر نعومة

// استخدام لون خلفية أغمق قليلاً للحصول على مظهر أكثر فخامة
        this.ctx.fillStyle = 'rgba(50, 50, 50, 0.7)';

// رسم المستطيل المستدير
//         this.ctx.beginPath();
//         this.ctx.moveTo(x + radius, y);
//         this.ctx.arcTo(x + width, y, x + width, y + height, radius);
//         this.ctx.arcTo(x + width, y + height, x, y + height, radius);
//         this.ctx.arcTo(x, y + height, x, y, radius);
//         this.ctx.arcTo(x, y, x + width, y, radius);
//         this.ctx.closePath();
//         this.ctx.fill();

// استخدام لون ذهبي للنص مع ظل أكثر نعومة
        this.ctx.fillStyle = 'gold';
        this.ctx.shadowColor = 'rgba(0, 0, 0, 0.5)'; // ظل أغمق قليلاً
        this.ctx.shadowBlur = 8; // ظل أكثر نعومة
        this.ctx.shadowOffsetX = 4; // زيادة إزاحة الظل قليلاً
        this.ctx.shadowOffsetY = 4;
    }

    applyStyle3(): void {
        const gradient = this.ctx.createLinearGradient(0, 0, this.canvas.width, 0);
        gradient.addColorStop(0, 'blue');
        gradient.addColorStop(1, 'green');
        this.ctx.fillStyle = 'white';
        this.ctx.strokeStyle = gradient;
        this.ctx.lineWidth = 5;
    }


    // tslint:disable-next-line:typedef
    public getAuthor(items: any | any[]) {
        return this.authors.filter((item: { id: number; }) => {
            return items.includes(item.id);
        });
    }

    // tslint:disable-next-line:typedef
    public getAuthorFromBackend() {
        return this.authorFromBackendService.getAuthorsForQuotes().subscribe((data: Author[]) => {
            this.authors = data;
        });
    }

    // tslint:disable-next-line:typedef
    public getTestimonialsFromBackend() {
        return this.testimonialsFromBackendService.getQuotesByStamp(this.stamp).subscribe((data: Testimonials[]) => {
            this.quotes = data;
        });
    }

    public getAllFromBackend(): void {
        this.getAuthorFromBackend();
        this.getTestimonialsFromBackend();
    }

    ngOnInit(): void {
        this.getAllFromBackend();
        this.isWebShareAvailable = !!navigator.share;
    }

    // toggleIconDrawing(): void {
    //     this.drawIcon = !this.drawIcon;
    //     if (this.drawIcon) {
    //         this.drawIconOnCanvas(); // This will draw the icon on the canvas
    //     } else {
    //         this.clearIconFromCanvas(); // This will clear the icon from the canvas
    //     }
    // }
    // toggleIconDrawing2(): void {
    //     this.drawIcon2 = !this.drawIcon2;
    //     if (this.drawIcon2) {
    //         this.drawIconOnCanvas2(); // This will draw the icon on the canvas
    //     } else {
    //         this.clearIconFromCanvas2(); // This will clear the icon from the canvas
    //     }
    // }
    toggleIconDrawing(): void {
        this.drawIcon = !this.drawIcon;
        this.redrawCanvas(); // Call a new function to update the canvas
    }

    toggleIconDrawing2(): void {
        this.drawIcon2 = !this.drawIcon2;
        this.redrawCanvas(); // Call a new function to update the canvas
    }

    async drawIconOnCanvas(): Promise<void> {

        const authors = this.getAuthor(this.selectedQuotes.author);
        const auothorId = authors[0].id;
        const author3 = this.authors.find(author => author.id === auothorId);
        const icon = await this.loadImage(author3.img);
        const iconSize = 90;
        const xOffset = 10;
        const yOffset = 10;

        this.ctx.save();

        // Draw a larger, blurred circle behind the icon to simulate a shadow effect
        const shadowBlur = 5;
        this.ctx.beginPath();
        this.ctx.arc(iconSize / 2 + xOffset, iconSize / 2 + yOffset, iconSize / 2 + shadowBlur, 0, Math.PI * 2, true);
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Shadow color
        this.ctx.filter = 'blur(5px)';
        this.ctx.fill();

        // Reset the filter property to prevent it from affecting subsequent drawings
        this.ctx.filter = 'none';

        // Draw the icon on top of the shadowed circle
        this.ctx.drawImage(icon, xOffset, yOffset, iconSize, iconSize);
        this.ctx.restore();
    }

    async drawIconOnCanvas2(): Promise<void> {

        const icon2 = await this.loadImage('assets/img/logo-3.webp');
        const iconSize2 = 90;
        const xOffset2 = this.canvas.width - 100;
        const yOffset2 = 10;

        this.ctx.save();

        // Draw a larger, blurred circle behind the icon to simulate a shadow effect
        const shadowBlur = 5;
        this.ctx.beginPath();
        this.ctx.arc(iconSize2 / 2 + xOffset2, iconSize2 / 2 + yOffset2, iconSize2 / 2 + shadowBlur, 0, Math.PI * 2, true);
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Shadow color
        this.ctx.filter = 'blur(5px)';
        this.ctx.fill();

        // Reset the filter property to prevent it from affecting subsequent drawings
        this.ctx.filter = 'none';

        // Draw the icon on top of the shadowed circle
        this.ctx.drawImage(icon2, xOffset2, yOffset2, iconSize2, iconSize2);
        this.ctx.restore();
    }

    async clearIconFromCanvas(): Promise<void> {
        const iconSize = 130;  // Ensure this is the same as in drawIconOnCanvas
        const xOffset = 0;
        const yOffset = 0;

        // Clear the icon area
        this.ctx.clearRect(xOffset, yOffset, iconSize, iconSize);

        // Redraw the portion of the background image that was behind the icon
        const img = await this.loadImage(this.selectedImage);
        this.ctx.drawImage(img, xOffset, yOffset, iconSize, iconSize, xOffset, yOffset, iconSize, iconSize);
    }

    async clearIconFromCanvas2(): Promise<void> {
        const iconSize = 130;  // Ensure this is the same as in drawIconOnCanvas
        const xOffset = this.canvas.width - 110;
        const yOffset = 0;

        // Clear the icon area
        this.ctx.clearRect(xOffset, yOffset, iconSize, iconSize);

        // Redraw the portion of the background image that was behind the icon
        const img = await this.loadImage(this.selectedImage);
        this.ctx.drawImage(img, xOffset, yOffset, iconSize, iconSize, xOffset, yOffset, iconSize, iconSize);
    }

    // async redrawCanvas(): Promise<void> {
    //     if (!this.canvas || !this.ctx) {
    //         throw new Error('Canvas or context not initialized');
    //     }
    //
    //     // Clear the canvas area
    //     this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    //
    //     // Redraw the background image
    //     const img = await this.loadImage(this.selectedImage);
    //     this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
    //
    //     // Redraw the text with or without the shadow box
    //     this.ctx.font = `33px ${this.randomFont}`;
    //     this.ctx.textAlign = 'center';
    //     this.ctx.textBaseline = 'middle';
    //     this.ctx.fillStyle = 'white';
    //     this.wrapText(this.ctx, this.textToAdd, this.canvas.width / 2, this.canvas.height / 2, 600, 40);
    //
    //     // Draw icons based on the state
    //     if (this.drawIcon) {
    //         await this.drawIconOnCanvas();
    //     }
    //     if (this.drawIcon2) {
    //         await this.drawIconOnCanvas2();
    //     }
    // }
    async redrawCanvas(): Promise<void> {
        if (!this.canvas || !this.ctx) {
            throw new Error('Canvas or context not initialized');
        }

        // Clear the canvas area
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        // Redraw the background image
        const img = await this.loadImage(this.selectedImage);
        this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);

        // Redraw the text with or without the shadow box
        // this.ctx.font = `33px ${this.randomFont}`;
        // this.ctx.textAlign = 'center';
        // this.ctx.textBaseline = 'middle';
        // this.ctx.fillStyle = 'white';
        this.wrapText(this.ctx, this.textToAdd, this.canvas.width / 2, this.canvas.height / 2, 600, 40);

        // Draw icons based on the state
        if (this.drawIcon) {
            await this.drawIconOnCanvas();
        }
        if (this.drawIcon2) {
            await this.drawIconOnCanvas2();
        }

        // Draw the bottom text based on the state
        if (this.drawBottomText) {
            this.drawBottomText2();
        }
    }

    isTextTooLong(context: CanvasRenderingContext2D, text: string, font: string, maxWidth: number): boolean {
        context.font = font;
        const words = text.split(' ');
        let line = '';
        let numberOfLines = 1;

        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);

            if (metrics.width > maxWidth && n > 0) {
                numberOfLines++;
                line = words[n] + ' ';
            } else {
                line = testLine;
            }
        }

        return numberOfLines > 3;
    }

    toggleShadowBox(): void {
        this.addShadowBox = !this.addShadowBox;
        this.redrawTextWithShadowToggle();
    }

    async redrawTextWithShadowToggle(): Promise<void> {
        if (!this.canvas || !this.ctx) {
            throw new Error('Canvas or context not initialized');
        }

        // Clear the canvas area where the text will be drawn
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        // Redraw the background image
        const img = await this.loadImage(this.selectedImage);
        this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);

        // Redraw the text with or without the shadow box
        // this.ctx.font = `33px ${this.randomFont}`;
        // this.ctx.textAlign = 'center';
        // this.ctx.textBaseline = 'middle';
        // this.ctx.fillStyle = 'white';
        this.wrapText(this.ctx, this.textToAdd, this.canvas.width / 2, this.canvas.height / 2, 600, 40);
    }

    toggleBottomText(): void {
        this.showBottomText = !this.showBottomText;
        const authors = this.getAuthor(this.selectedQuotes.author);
        const auothorId = authors[0].id;
        const author3 = this.authors.find(author => author.id === auothorId);
        this.bottomText = author3.name;
        this.redrawCanvas();
    }

    async drawBottomText(): Promise<void> {
        if (!this.authors || !this.selectedQuotes) {
            console.error('Authors or selectedQuotes data is not available.');
            return;
        }
        // Draw the entire image
        const img = await this.loadImage(this.selectedImage);
        this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);

        // Use the latest style set for wrapText
        this.wrapText(this.ctx, this.textToAdd, this.canvas.width / 2, this.canvas.height / 2, 600, 40);
        this.drawBottomText2();
    }

    // tslint:disable-next-line:typedef
    drawBottomText2() {
        if (!this.showBottomText) {
            return;
        }

        const padding = 10;
        const x = this.canvas.width / 2;
        const y = this.canvas.height - padding;

        // Reset to the specific style for bottom text
        this.ctx.font = `20px ${this.randomFont}`;
        this.ctx.textAlign = 'center';
        this.ctx.textBaseline = 'bottom';
        this.ctx.fillStyle = 'white';

        this.ctx.fillText(this.bottomText, x, y);
    }

    applyRandomTextFromList(): void {
        do {
            this.selectedQuotes = this.getRandomItem(this.quotes);
            this.textToAdd = this.selectedQuotes.comment;
        } while (this.isTextTooLong(this.ctx, this.textToAdd, `26px ${this.randomFont}`, 600));
        this.updateCanvas();
    }

    selectSampHeiligenn(): void {
        this.resetCanvas();
        this.stamp = 'Heiligen';
        this.ngOnInit();
        this.getRandomGeneralImage();
        this.changeStyle();
        this.applyRandomTextFromList();
    }

    selectSampBible(): void {
        this.resetCanvas();
        this.stamp = 'Bible';
        this.ngOnInit();
        this.getRandomGeneralImage();
        this.changeStyle();
        this.applyRandomTextFromList();
    }

    selectSampWeihnachten(): void {
        this.resetCanvas();
        this.stamp = 'Weihnachten';
        this.ngOnInit();
        this.getRandomGeneralImage();
        this.applyRandomTextFromList();
    }
    selectSampLicht(): void {
        this.resetCanvas();
        this.stamp = 'Licht';
        this.ngOnInit();
        this.getRandomGeneralImage();
        this.applyRandomTextFromList();
    }
    selectSampOstern(): void {
        this.resetCanvas();
        this.stamp = 'Ostern';
        this.ngOnInit();
        this.getRandomGeneralImage();
        this.applyRandomTextFromList();
    }
    mergeRandomImageAndText(): void {
        this.stamp = this.getRandomItem(this.stampList);
        this.changeBackgroundImageRandomly();
        this.applyRandomTextFromList();
        this.changeStyle();
    }

    handleFileInput(event: Event): void {
        const file = (event.target as HTMLInputElement).files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                this.selectedImage = e.target.result as string;
                this.updateCanvas();
            };
            reader.readAsDataURL(file);
        }
    }

    changeBackgroundImage(): void {
        this.fileInput.nativeElement.click();
    }

    changeStyle(): void {
        this.selectedStyle = Math.floor(Math.random() * 4) + 1;
        this.updateCanvas();
    }

    changeBackgroundImageRandomly(): void {
        // Logic to change the background image randomly
        this.getRandomGeneralImage();
        this.updateCanvas();
    }

    selectSampInfo(): void {
        this.resetCanvas();
        this.stamp = 'Info';
        this.ngOnInit();
        this.getRandomGeneralImage();
        this.applyRandomTextFromList();
    }

    selectSampFrage(): void {
        this.resetCanvas();
        this.stamp = 'Frage';
        this.ngOnInit();
        this.getRandomGeneralImage();
        this.applyRandomTextFromList();
    }

   public incresSize(): void{
        this.textSize = this.textSize + 1 ;
        this.lineHeight = this.textSize * 1.5;
        this.updateCanvas();
    }
    public decresSize(): void{
        this.textSize = this.textSize - 1 ;
        this.lineHeight = this.textSize * 1.5;
        this.updateCanvas();
    }
    // tslint:disable-next-line:typedef
    applyFillStyle() {
        const ctx = this.canvasElement.nativeElement.getContext('2d');
        ctx.fillStyle = this.fillStyle;
        ctx.fillRect(0, 0, 200, 200);
        // this.ctx.fillStyle = this.fillStyle;
        this.updateCanvas();
    }

    // tslint:disable-next-line:typedef
    onColorChange() {
        this.fillStyle = this.selectedColor;
        this.applyFillStyle();
    }

    generateRandomGradient(): void {
        const ctx = this.canvasElement.nativeElement.getContext('2d');
        const gradient = this.ctx.createLinearGradient(0, 0, 200, 0);

        // Generate two random colors for the gradient
        gradient.addColorStop(0, this.randomColor());
        gradient.addColorStop(1, this.randomColor());
        ctx.createLinearGradient = this.ctx.createLinearGradient;
        this.fillStyle = gradient;
        this.applyFillStyle();
           }

    randomColor(): string {
        return `#${Math.floor(Math.random() * 16777215).toString(16).padEnd(6, '0')}`;
    }
}
