import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-wachsen-im-geist',
  templateUrl: './wachsen-im-geist.component.html',
  styleUrls: ['./wachsen-im-geist.component.css']
})
export class WachsenImGeistComponent implements OnInit {
// Footer style
  classname = '';
  ftlogo = 'assets/img/logo-2.png';
  ftshape = 'assets/img/lines/01.png';
  ftshape2 = 'assets/img/lines/02.png';
  ftshapeclass = 'line-one';
  ftshape2class = 'line-two';

  ngOnInit(): void {
  }
}
