import { Component, OnInit } from '@angular/core';
import {BlogFromBackendService} from '../../../service/blog-from-backend.service';
import {Blog} from '../../../data/model/blog.model';
import lehrnmatrialcategory from '../../../data/lehrnmatrial/lehrnmatrialcategory.json';
import blogtags from '../../../data/tags.json';

@Component({
  selector: 'app-lernmaterialsidebar',
  templateUrl: './lernmaterialsidebar.component.html',
  styleUrls: ['./lernmaterialsidebar.component.css']
})
export class LernmaterialsidebarComponent implements OnInit {

  constructor( private blogFromBackendService: BlogFromBackendService) { }
  public blog: Blog[];
  // public blogcategory = blogcategory;
  public lehrnmatrialcategory = [
    {
      id: 0,
      title: 'blogText121',
      isChecked: false
    },
    {
      id: 1,
      title: 'blogText122',
      isChecked: false
    },
    {
      id: 2,
      title: 'blogText123',
      isChecked: false
    },
    {
      id: 3,
      title: 'blogText124',
      isChecked: false
    },
    {
      id: 4,
      title: 'blogText125',
      isChecked: false
    }
  ];
  public blogtags = [
    {
      id: 1,
      title: 'blogText105',
      isChecked: false

    },
    {
      id: 2,
      title: 'blogText106',
      isChecked: false
    },
    {
      id: 3,
      title: 'blogText107',
      isChecked: false
    },
    {
      id: 4,
      title: 'blogText108',
      isChecked: false
    },
    {
      id: 5,
      title: 'blogText109',
      isChecked: false
    },
    {
      id: 6,
      title: 'blogText110',
      isChecked: false
    },
    {
      id: 7,
      title: 'blogText111',
      isChecked: false
    },
    {
      id: 8,
      title: 'blogText112',
      isChecked: false
    }
  ];
  public getBlogFromBackend(): any{
    return this.blogFromBackendService.getBlogs().subscribe((data: Blog[]) => {
      this.blog = data;
    });
  }
  ngOnInit(): void {
    this.getBlogFromBackend();
  }


}
