import { Injectable } from '@angular/core';
import {Author} from '../data/model/author.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Blog} from '../data/model/blog.model';
import {environment} from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthorFromBackendService {
  lang = localStorage.getItem('lang') || 'de';
  private apiUrl: string = environment.apiUrl;
  // private urlAuthors2test = 'http://localhost:8080/author/';
  private urlAuthors = this.apiUrl + '/author/';
  private urlAuthorsForTestimonials =  this.apiUrl + '/author/testimonial/';
  private urlAuthorsForAllTestimonials =  this.apiUrl + '/author/testimonial/all/';
  private urlAuthorsForQuotes =  this.apiUrl + '/author/quotes/';
  // private urlAuthorsForQuotes =  this.apiUrl + '/author/quotes';
  private urlAuthorsForBlog =  this.apiUrl + '/author/blog/';
  private urlAuthorID =  this.apiUrl + '/author/id/';
  private urlId = this.apiUrl + '/idsAccumulation/author';
  constructor(private httpClient: HttpClient) { }

  public getAuthors(): any{
     return this.httpClient.get<Author[]>(this.urlAuthors +  this.lang);
  }
  public getAuthorsForTestimonials(): Observable<Author[]>{
    return this.httpClient.get<Author[]>(this.urlAuthorsForTestimonials +  this.lang);
  }
  public getAuthorsForQuotes(): Observable<Author[]>{
    return this.httpClient.get<Author[]>(this.urlAuthorsForQuotes +  this.lang);
  }
  public getAuthorsInType(type: string): Observable<Author[]>{
    return this.httpClient.get<Author[]>(this.urlAuthors + type + '/' +  this.lang);
  }
  public getAuthorsForBlog(): Observable<Author[]>{
    return this.httpClient.get<Author[]>(this.urlAuthorsForBlog +  this.lang);
  }
    public getAuthorsForAllTes(): Observable<Author[]>{
        return this.httpClient.get<Author[]>(this.urlAuthorsForAllTestimonials +  this.lang);
    }
  // tslint:disable-next-line:typedef
  postNewAuthor( newAuthor: Author) {
    return this.httpClient.post(this.urlAuthors, newAuthor);
  }

  // tslint:disable-next-line:typedef
    getAuthorsForSearchById(id: number) {
      return this.httpClient.get<Author[]>(this.urlAuthorID + id);
    }
// tslint:disable-next-line:typedef
  getAuthorsForSearch(txt: string) {
    return this.httpClient.get<Author[]>(this.urlAuthors + txt + '/' +  this.lang);
  }

  // tslint:disable-next-line:typedef
  deleteAuthor(id: number) {
    this.httpClient.delete(this.urlAuthors + id).subscribe(data => {
      console.log(data);
    });
  }

  // tslint:disable-next-line:typedef
    updateAuthor(id: number, uAuthor: Author) {
      return this.httpClient.put(this.urlAuthors + id, uAuthor);
    }

  // tslint:disable-next-line:typedef
    updateAuthorID() {
      return this.httpClient.put(this.urlId, ' {}').subscribe(data => {
      });
    }

  // tslint:disable-next-line:typedef
  getAuthorID() {
    return this.httpClient.get(this.urlId);
  }
}

