import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SiteSetting} from '../data/model/siteSetting.model';


@Injectable({
  providedIn: 'root'
})
export class SiteSittingService {
  public   loginflag = false;
  private apiUrl: string = environment.apiUrl;
  private urlSiteSetting = this.apiUrl + '/siteSetting';
  constructor(private httpClient: HttpClient) { }

  // tslint:disable-next-line:typedef
  async getSitesitting(id: string) {
    return this.httpClient.get<SiteSetting>(this.urlSiteSetting + id);
  }
  public getLoginFlag(): boolean{
    return this.loginflag;
  }
  public updateSiteSettingLang(lang: SiteSetting): any {
    // alert(this.httpClient.put(this.urlSiteSettingStampName, stampName).forEach(e => alert(e)));
    return this.httpClient.put(this.urlSiteSetting + '/3', lang).subscribe(data => {
       alert('lang change to ' + lang.lang);
       // window.location.reload();
    });
  }
}
