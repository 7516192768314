<div class="container">
    <div class="cta-wrap bg-img-c" style="background-image: url(assets/img/lines/16.png);">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="cta-content text-center">
                    <div class="section-title both-border mb-30">
                        <span class="title-tag">Get A Quote</span>
                        <h2 class="title">Feel Any Project For Business Consulting Get Started Us</h2>
                    </div>
                    <a routerLink="/contact" class="main-btn main-btn-3">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</div>