import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BreadcrumbService, Breadcrumb} from 'angular-crumbs';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {AnalyticsConsentModalComponent} from './components/shared/analytics-consent-modal/analytics-consent-modal.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    lang = localStorage.getItem('lang');
    constructor(private titleService: Title, private breadcrumbService: BreadcrumbService, private translateService: TranslateService,
                public dialog: MatDialog) {
        this.lang = localStorage.getItem('lang') || 'de'; // Get language from local storage or set to 'de' if not available
        translateService.setDefaultLang('de'); // Set default language to 'de'
        translateService.use(this.lang); // Use the stored language

        document.documentElement.lang = this.lang;
    }

    ngOnInit(): void {
        this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
            this.titleService.setTitle(this.createTitle(crumbs));
        });
        this.openDialog();
    }

    // openDialog(): void {
    //     this.dialog.open(AnalyticsConsentModalComponent, {
    //         width: '400px',
    //         panelClass: 'custom-dialog-container'
    //     });
    // }
    openDialog(): void {
        // Check if consent has already been given
        const consentGiven = localStorage.getItem('consentGiven');
        if (!consentGiven) {
            this.dialog.open(AnalyticsConsentModalComponent, {
                width: '400px',
                panelClass: 'custom-dialog-container'
            });
        }
    }

    // tslint:disable-next-line:typedef
    onActivate(event) {
        window.scroll(0, 0);
    }

    // tslint:disable-next-line:typedef
    private createTitle(routesCollection: Breadcrumb[]) {
        const title = 'Die Antiochenisch-Orthodoxe Kirchengemeinde';
        const titles = routesCollection.filter((route) => route.displayName);

        if (!titles.length) {
            return title;
        }

        const routeTitle = this.titlesToString(titles);
        return `${title}${routeTitle}`;
    }

    // tslint:disable-next-line:typedef
    private titlesToString(titles) {
        return titles.reduce((prev, curr) => {
            return `${prev} | ${curr.displayName}`;
        }, '');
    }
}
