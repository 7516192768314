<div class="shop-sidebar">
    <div class="widget product-cat-widget">
        <h4 class="widget-title img-bg-rrr">{{'Kategorie'|translate}}</h4>
        <ul>
            <li *ngFor="let item of category">
                <a routerLink="/bibilothek/cat/{{item.id}}">{{item.title|translate}}</a>
            </li>
        </ul>
    </div>
    <div class="widget product-tag-widget">
        <h4 class="widget-title img-bg-rrr">{{'Beliebte Tags'|translate}}</h4>
        <div class="tags-loop">
            <a routerLink="/bibilothek/tag/{{item.id}}" *ngFor="let item of tags">{{item.title|translate}}</a>
        </div>
    </div>
<!--    <div class="widget product-filter-widget">-->
<!--        <h4 class="widget-title">Filer By Price</h4>-->
<!--        <div class="price-range-wrap">-->
<!--            <div class="slider-range">-->
<!--                <div id="slider-range"></div>-->
<!--            </div>-->
<!--            <div class="price-ammount">-->
<!--                <span>Price :</span>-->
<!--                <input type="text" id="amount" name="price" disabled>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</div>
