<!--<h2 mat-dialog-title>Cookie Consent</h2>-->
<!--<mat-dialog-content>-->
<!--    <p>We use Google Analytics to improve user experience. By continuing to use our website, you consent to the use of cookies in accordance with our Cookie Policy.</p>-->
<!--</mat-dialog-content>-->
<!--<mat-dialog-actions>-->
<!--    <button mat-button (click)="onClose()">I Understand</button>-->
<!--</mat-dialog-actions>-->
<h2 mat-dialog-title class="centered-text">{{'Cookie Zustimmung' | translate}} </h2>
<mat-dialog-content>
    <p><br>{{'blogText169' | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions class="centered-actions">
    <button class="main-btn less-thick"  (click)="onClose()">{{'akzeptieren' | translate}}</button>
</mat-dialog-actions>
