import { Component, OnInit, Input } from '@angular/core';
import instagram from '../../../data/instagram.json';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router: ActivatedRoute) { }
  @Input() layout: number | string;
  @Input() logo: number | string;
  @Input() shape: number | string;
  @Input() shape2: number | string;
  @Input() shapeclass: number | string;
  @Input() shape2class: number | string;

  // Instagram
  public instagram = instagram;

  // tslint:disable-next-line:typedef
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  ngOnInit(): void {
    }
  moveToMail(): void{
    const x = document.querySelector('#mailtarget');
    if (x){
      x.scrollIntoView();
    }
  }
}
