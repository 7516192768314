import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {Calendar2Component} from './calendar2.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [Calendar2Component],
  exports: [
    Calendar2Component
  ],
    imports: [
        CommonModule,
        NgbModule,
        SlickCarouselModule,
        TranslateModule
    ]
})
export class Calendar2Module { }
