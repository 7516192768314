import {Component, OnInit, AfterViewInit, OnDestroy, HostListener} from '@angular/core';
import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import {TranslateService} from '@ngx-translate/core';
import allLocales from '@fullcalendar/core/locales-all';
import {Subscription} from 'rxjs';
import {CalendarEvent, CalendarService} from 'src/app/service/calendar-service.service';
import { ElementRef } from '@angular/core';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
    // Gebet
    calendar: Calendar;

    // ننمو بالروح Wachsen im Geist
        langChangeSubscription: Subscription;
    lang = localStorage.getItem('lang');

    constructor(private elementRef: ElementRef, private translateService: TranslateService, private calendarService: CalendarService) {
    }

    @HostListener('window:resize', ['$event'])
    // tslint:disable-next-line:typedef
    onResize(event) {
        this.toggleListButtons();
    }

    ngOnInit(): void {
        this.initCalendar();
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.updateCalendarLocale(this.translateService.currentLang);
        });
    }

    ngAfterViewInit(): void {
        this.calendar.render();
        this.addCustomButtonClasses();
        this.toggleListButtons();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        if (this.calendar) {
            this.calendar.destroy();
        }
    }

    private generateICS(eventDetails: { title: string; start: Date; end: Date; }): string {
        const start = eventDetails.start.toISOString().replace(/-|:|\.\d+/g, '');
        const end = eventDetails.end.toISOString().replace(/-|:|\.\d+/g, '');

        return `
BEGIN:VEVENT
DTSTART:${start}
DTEND:${end}
SUMMARY:${eventDetails.title}
END:VEVENT
    `.trim();
    }

    private downloadSingleEvent(event: any): void {
        const eventDetails = {
            title: event.title,
            start: event.start,
            end: event.end
        };

        const icsContentForSingleEvent = `
BEGIN:VCALENDAR
VERSION:2.0
${this.generateICS(eventDetails)}
END:VCALENDAR
    `.trim();

        this.downloadICS(icsContentForSingleEvent, `${event.title}.ics`);
    }

    private downloadICS(icsContent: string, filename: string): void {
        const blob = new Blob([icsContent], {type: 'text/calendar'});

        // Create an anchor element with a download attribute
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = filename;

        // Trigger a click event on the anchor element
        downloadLink.dispatchEvent(new MouseEvent('click'));

        // Cleanup
        URL.revokeObjectURL(downloadLink.href);
    }

    // tslint:disable-next-line:typedef
//     public downloadEntireCalendar() {
//         const events = this.calendar.getEvents();
//         let allEventsICS = '';
//
//         events.forEach(event => {
//             const eventDetails = {
//                 title: event.title,
//                 start: event.start,
//                 end: event.end
//             };
//             allEventsICS += this.generateICS(eventDetails);
//         });
//
//         const fullICSContent = `
// BEGIN:VCALENDAR
// VERSION:2.0
// ${allEventsICS}
// END:VCALENDAR
//     `.trim();
//
//         this.downloadICS(fullICSContent, 'FullCalendar.ics');
//     }
    // tslint:disable-next-line:typedef
    public downloadEntireCalendar() {
        const events = this.calendar.getEvents();
        let allEventsICS = '';

        events.forEach(event => {
            const eventDetails = {
                title: event.title,
                start: event.start,
                end: event.end
            };
            allEventsICS += this.generateICS(eventDetails) + '\n'; // Add a newline after each event
        });

        const fullICSContent = `
BEGIN:VCALENDAR
VERSION:2.0
${allEventsICS}
END:VCALENDAR
    `.trim();

        this.downloadICS(fullICSContent, 'FullCalendar.ics');
    }
    private initCalendar(): void {
        this.calendar = new Calendar(document.getElementById('calendar'), {
            // tslint:disable-next-line:typedef
            eventClick: (info) => {
                info.jsEvent.preventDefault();

                // Create a custom modal or dialog with three buttons: Download Event, Download Calendar, and Cancel
                const modalContent = `

<div id="customModal" style="display: none; color: #81291B; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: white; padding: 20px; border: 1px solid #ccc; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); border-radius: 5px; text-align: center;">

    <p>${this.translateService.instant('blogText166')}</p>
    <br>
    <button id="downloadEventButton" style="background-color: #EBD47B; color: #81291B; padding: 15px; margin-bottom: 10px; border: none; border-radius: 5px; cursor: pointer;">${this.translateService.instant('Ereignis hinzufügen')}</button>
    <button id="downloadCalendarButton" style="background-color: #EBD47B; color: #81291B; padding: 15px; margin-bottom: 10px; border: none; border-radius: 5px; cursor: pointer;">${this.translateService.instant('Alle Ereignisse hinzufügen')}</button>
    <button id="cancelButton" style="background-color: #EBD47B; color: #81291B; padding: 15px; border: none; border-radius: 5px; cursor: pointer;">${this.translateService.instant('Cancel')}</button>

</div>
`;

// Add media query for small devices
                const smallDeviceStyles = `
@media only screen and (max-width: 767px) {
    #customModal {
        width: 80%; /* Adjust as needed */
    }

    /* Increase spacing between buttons on small devices */
    #downloadEventButton,
    #downloadCalendarButton,
    #cancelButton {
        margin-bottom: 15px;
    }

    /* Add additional styles for small devices if needed */
}
`;


                const styleTag = document.createElement('style');
                styleTag.appendChild(document.createTextNode(smallDeviceStyles));
                document.head.appendChild(styleTag);
                // Append modal content to the body
                document.body.insertAdjacentHTML('beforeend', modalContent);

                // Get references to the buttons
                const downloadEventButton = document.getElementById('downloadEventButton');
                const downloadCalendarButton = document.getElementById('downloadCalendarButton');
                const cancelButton = document.getElementById('cancelButton');

                // Add click event listeners to the buttons
                downloadEventButton.addEventListener('click', () => {
                    this.downloadSingleEvent(info.event);
                    this.closeCustomModal();
                });

                downloadCalendarButton.addEventListener('click', () => {
                    this.downloadEntireCalendar();
                    this.closeCustomModal();
                });

                cancelButton.addEventListener('click', () => {
                    // Additional logic for canceling the download, if needed
                    this.closeCustomModal();
                });

                // Show the modal
                this.showCustomModal();
            },
            now: undefined,
            eventsSet: (events) => this.updateCalendarEvents(events),
            plugins: [dayGridPlugin, listPlugin, googleCalendarPlugin],
            initialView: 'listMonth',
            googleCalendarApiKey: 'AIzaSyDhZv2qrOxH4_AFS4t7Smurws_XyB9cDwM',
            navLinks: false,
            locales: allLocales,
            views: {
                listDay: {buttonText: this.translateService.instant('LIST_DAY', this.lang)},
                listWeek: {buttonText: this.translateService.instant('LIST_WEEK', this.lang)},
                listMonth: {buttonText: this.translateService.instant('LIST_MONTH', this.lang)},
                listYear: {buttonText: this.translateService.instant('LIST_YEAR', this.lang)}
            },
            locale: this.translateService.currentLang,
            headerToolbar: {
                left: 'prev,listDay,listWeek',
                center:  'title',
                right: 'listMonth,listYear,next'
            },
            eventSources: [
                {
                    googleCalendarId: 'antiochorthodoxmedia@gmail.com',
                },
                {
                    googleCalendarId: '0f26ee9283d86ac2307f87c6d002e6818091627fecdc2082ac5ff7ab289c2aff@group.calendar.google.com',
                },
                {
                    googleCalendarId: '28eb6700db399259f8e86bb0662d30039445bf51b75054061dacc5522c704eaf@group.calendar.google.com',
                },
                {
                    googleCalendarId: 'a0c4f669c53dedad6186acc7b16afa3aa27366cefa71c4ea91536640da60a40b@group.calendar.google.com',
                },
            ]
        });
    }

// tslint:disable-next-line:align
    updateCalendarLocale(lang: string): void {
        this.calendar.setOption('locale', lang);
    }

    private toggleListButtons(): void {
        const isMobile = window.innerWidth < 768;
        const listButtons = ['listDay', 'listWeek', 'listMonth', 'listYear'];

        listButtons.forEach(button => {
            const buttonElement = document.querySelector('.fc-' + button + '-button') as HTMLElement;
            if (buttonElement) {
                buttonElement.style.display = isMobile ? 'none' : 'inline-block';
            }
        });
    }

    private updateCalendarEvents(events): void {
        // Transform and check events
        const calendarEvents: CalendarEvent[] = events.map(event => ({
            title: event.title,
            start: event.start,
            end: event.end
        }));

        // console.log('Fetched events:', calendarEvents); // Check fetched events

        // Update the events in CalendarService
        this.calendarService.setEvents(calendarEvents);
    }

    private showCustomModal(): void {
        const modal = document.getElementById('customModal');
        if (modal) {
            modal.style.display = 'block';
        }
    }
    private showCustomModal2(): void {
        const modal = document.getElementById('customModal2');
        if (modal) {
            modal.style.display = 'block';
        }
    }
    private closeCustomModal(): void {
        const modal = document.getElementById('customModal');
        if (modal) {
            modal.style.display = 'none';
            modal.parentNode.removeChild(modal);
        }
    }
    private closeCustomModal2(): void {
        const modal = document.getElementById('customModal2');
        if (modal) {
            modal.style.display = 'none';
            modal.parentNode.removeChild(modal);
        }
    }
    private addCustomButtonClasses(): void {
        const headerElement = this.elementRef.nativeElement.querySelector('.fc-header-toolbar');
        if (headerElement) {
            const prevButton = headerElement.querySelector('.fc-prev-button');
            const nextButton = headerElement.querySelector('.fc-next-button');
            if (prevButton) {
                prevButton.classList.add('main-btn4');
            }
            if (nextButton) {
                nextButton.classList.add('main-btn4');
            }
        }
        const listMonthButton = headerElement.querySelector('.fc-listMonth-button');
        const listYearButton = headerElement.querySelector('.fc-listYear-button');
        if (listMonthButton) {
            listMonthButton.classList.add('main-btn4');
            listMonthButton.style.color = '#81291B';
        }
        if (listYearButton) {
            listYearButton.classList.add('main-btn4');
            listYearButton.style.color = '#81291B';
        }
        const listDayButton = headerElement.querySelector('.fc-listDay-button');
        const listWeekButton = headerElement.querySelector('.fc-listWeek-button');
        if (listDayButton) {
            listDayButton.classList.add('main-btn4');
            listDayButton.style.color = '#81291B';
        }
        if (listWeekButton) {
            listWeekButton.classList.add('main-btn4');
            listWeekButton.style.color = '#81291B';
        }
    }
   }
